import { IAction } from '@src/types/IAction';
import { CLEAR_MATERIAL_STOCK_STATES, MATERIAL_STOCK_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IMaterialStockReducer } from '../types/IMaterialStockReducer';

const INITIAL_STATE: IMaterialStockReducer = {

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function MaterialStockReducer(state = INITIAL_STATE, { type, payload }: IAction): IMaterialStockReducer {

  switch (type) {

    case CLEAR_MATERIAL_STOCK_STATES: return INITIAL_STATE;

    case MATERIAL_STOCK_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
