import * as $AT from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IAnnouncementReducer } from '../types/IAnnouncementReducer';

const INITIAL_STATE: IAnnouncementReducer = {
  deleteIdList: [],
  popupState: {
    key: '',
    label: '',
    open: false,
  }
};

export default function AnnouncementReducer(state = INITIAL_STATE, { type, payload }: IAction): IAnnouncementReducer {

  switch (type) {


    case $AT.CLEAR_ANNOUNCEMENTS: {

      return INITIAL_STATE;

    }

    case $AT.ANNOUNCEMENT_STATE_UPDATE: {

      return { ...state, ...payload };

    }

    case $AT.CLEAR_ANNOUNCEMENT_STATE: {

      return { ...state, announcement: undefined };

    }

    default: return state;
  }

}
