import * as $AT from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import { IKitchenReducer } from '../types/IKitchenReducer';

const INITIAL_STATE: IKitchenReducer = {

};

const KitchenReducer = (state:IKitchenReducer = INITIAL_STATE, { type, payload }: AnyAction):IKitchenReducer => {

  switch (type) {

    case $AT.KITCHEN_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

};


export default KitchenReducer;
