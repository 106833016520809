import Store from '../../utils/redux/store';

type Action = {
    type: string,
    payload?: any
}

export function dispatcher(action: Action) {

  Store.dispatch(action);

}

export function getState() {

  return Store.getState();

}
