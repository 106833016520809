import {
  CLEAR_STAFFS_STATE,
  STAFF_SHIFT_UPDATE,
  STAFF_STATE_UPDATE,
  UPDATED_STAFF_OBJECT,
  UPDATED_STAFF_OBJECT_ERROR
} from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import { IStaffReducerType } from '../types/IStaffReducerType';

export const INITIAL_STATE = {
  updatedStaff: undefined,
  updatedStaffErrors: undefined,
  selectedStaff: undefined,
  isRefresh: false,
  selectedStafssShifts: [],
  shifts: []
};

const StaffReducer = (state: IStaffReducerType = INITIAL_STATE, { type, payload }: AnyAction): IStaffReducerType => {

  switch (type) {

    case STAFF_STATE_UPDATE: return { ...state, ...payload };

    case CLEAR_STAFFS_STATE: return INITIAL_STATE;

    case UPDATED_STAFF_OBJECT: return { ...state, updatedStaff: { ...state.updatedStaff, [payload.property]: payload.value }, };

    case UPDATED_STAFF_OBJECT_ERROR: return {
      ...state,
      updatedStaff: { ...state.updatedStaff, [payload.property]: payload.value },
      updatedStaffErrors: { ...state.updatedStaffErrors, [payload.property]: payload.isError },

    };

    case STAFF_SHIFT_UPDATE: return { ...state, shifts: payload };

    default: return { ...state, };
  }

};

export default StaffReducer;
