import * as $AT from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import * as GetReducerKey from '../services/GetReducerKey';

const INITIAL_STATE = {};

const OpsiyonTableReducer = (state: any = INITIAL_STATE, { type, payload }: AnyAction) => {

  switch (type) {

    case $AT.OPS_TABLE_STATE_UPDATE: {

      return { ...state, ...payload };

    }

    case $AT.CLEAR_OPS_TABLE_STATE: {

      return { ...INITIAL_STATE };

    }

    case $AT.CLEAR_ALL_OPS_TABLE_STATE: {

      return { ...INITIAL_STATE };

    }

    // Data listesinden gelen rowDatanın checked ını günceller
    case $AT.OPS_TABLE_ROW_CLICK: {

      const { tKey, rowData } = payload;

      const { [GetReducerKey.dataKey(tKey)]: tData } = state;

      // Table Data güncellemesi (rowData checked güncellenir),

      const _index = tData.findIndex((t: any) => t.id === rowData.id);

      tData[_index].tableData.checked = !tData[_index].tableData.checked;

      return { ...state, [GetReducerKey.dataKey(tKey)]: tData, };

    }

    default: return { ...state, };
  }

};
export default OpsiyonTableReducer;
