import { MaterialUsingEnum } from '@assets/enums/MaterialUsingEnum';
import * as $AT from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import { IDeskBasketReducer } from '../types/IDeskBasketReducer';

const INITIAL_STATE: IDeskBasketReducer = {

};

const DeskBasketReducer = (state:IDeskBasketReducer = INITIAL_STATE, { type, payload }: AnyAction):IDeskBasketReducer => {

  switch (type) {

    case $AT.DESK_BASKET_STATE_UPDATE: return { ...state, ...payload };

    case $AT.BASKET_UPDATE_SELECTABLE_MATERIAL: {

      if (state.selectableMaterials) {

        const materialId = payload;

        const currentIndex = state.selectableMaterials?.findIndex((s) => s.material.id === materialId);

        if (currentIndex !== -1) {

          const newList = [...state.selectableMaterials];

          newList[currentIndex].isTakeOut = !newList[currentIndex].isTakeOut;

          if (newList[currentIndex].isTakeOut === false && state?.extraMaterials) {

            const extra = [...state?.extraMaterials];

            const extIndex = extra.findIndex((s) => s.material?.id === newList[currentIndex].material?.id);

            if (extIndex !== -1) extra[extIndex].count = 0;

            return { ...state, selectableMaterials: [...newList], extraMaterials: [...extra], };

          }

          return { ...state, selectableMaterials: [...newList] };

        }

      }

      return state;

    }

    case $AT.BASKET_ADD_EXTRA_MATERIAL: {

      const currentIndex = state?.extraMaterials?.findIndex((s) => s.material.id === payload);

      if (currentIndex !== undefined && currentIndex !== -1 && state.extraMaterials) {

        const newList = [...state.extraMaterials];

        newList[currentIndex].count = Number(newList[currentIndex].count || 0) + 1;

        return { ...state, extraMaterials: newList };

      }

      return state;

    }

    case $AT.BASKET_DELETE_EXTRA_MATERIAL: {

      if (state.extraMaterials) {

        const currentIndex = state.extraMaterials?.findIndex((s) => s.material.id === payload);

        if (currentIndex !== -1) {

          const newList = [...state.extraMaterials];

          if (Number(newList[currentIndex]?.count) > 0) {

            newList[currentIndex].count = Number(newList[currentIndex].count || 0) - 1;

            return { ...state, extraMaterials: newList };

          }

        }

      }

      return state;

    }

    case $AT.BASKET_UPDATE_PRODUCT_PORTIONS: {

      const portionId = payload;

      const groupedMaterialRelations = state?.groupedMaterialRelations?.find((s) => s?.portion?.id === portionId);

      const materials = groupedMaterialRelations?.materialRelations?.filter((s) => s.visibleWhenOrder === true) || [];

      return {
        ...state,

        selectedPortionId: portionId,

        requiredMaterials: materials?.filter((s) => s.materialUsing === MaterialUsingEnum.Required),

        selectableMaterials: materials?.filter((s) => (s.materialUsing === MaterialUsingEnum.Selectable)),

        extraMaterials: materials?.filter((s) => (s.materialUsing === MaterialUsingEnum.OnlyExtra || s.materialUsing === MaterialUsingEnum.Selectable)
        && s?.additionalAmount !== undefined)

      };

    }

    default:
      return state;
  }

};

export default DeskBasketReducer;
