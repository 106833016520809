import React from 'react';
import Dynamic from 'next/dynamic';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ColorService from '@services/ColorService';
import BackDropUtils from '@components/utils/BackDropUtils';
import CookieHydrator from '../utils/auth/CookieHydrationProvider';
import RootBackground from './BackgroundProvider';
import FirebaseTrackingProvider from './FirebaseTrackingProvider';

const SnackbarUtilsConfigurator = Dynamic(() => import('@components/utils/SnackbarUtils').then((module) => module.SnackbarUtilsConfigurator), { ssr: false });

export default function CustomProvider({ children }: { children: any; }) {

  return (
    <FirebaseTrackingProvider>

      <MuiThemeProvider theme={ColorService()}>

        <BackDropUtils />

        <CookieHydrator>
          <RootBackground>
            {children}
          </RootBackground>
        </CookieHydrator>

        <SnackbarUtilsConfigurator />

      </MuiThemeProvider>
    </FirebaseTrackingProvider>
  );

}

