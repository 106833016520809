import { IAction } from '@src/types/IAction';
import { CLEAR_SUPPLIER_STATES, SUPPLIER_STATE_UPDATE } from '@utils/redux/actionTypes';
import { ISupplierDetailDTO } from '../types/ISupplierDetailDTO';

export interface ISupplierReducer {
  deleteIdList?: number[];
  popupState: {
    key: string;
    label?: string;
    open: boolean;
    supplier?: ISupplierDetailDTO;
  }
}

const INITIAL_STATE: ISupplierReducer = {
  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function SupplierReducer(state = INITIAL_STATE, { type, payload }: IAction): ISupplierReducer {

  switch (type) {

    case CLEAR_SUPPLIER_STATES: return INITIAL_STATE;

    case SUPPLIER_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
