import { IAction } from '@src/types/IAction';
import { CLEAR_OUT_OF_SALE_REVENUE_STATES, OUT_OF_SALE_REVENUE_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IOutOfSaleRevenueDTO } from '../types/IOutOfSaleRevenueDTO';

export interface IOutOfSaleRevenueReducer {
  deleteIdList?: string[];
  popupState: {
    key: string;
    label?: string;
    open: boolean;
    outOfSaleRevenue?: IOutOfSaleRevenueDTO;
  }
}

const INITIAL_STATE: IOutOfSaleRevenueReducer = {
  deleteIdList: [],
  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function OutOfSaleRevenueReducer(state = INITIAL_STATE, { type, payload }: IAction): IOutOfSaleRevenueReducer {

  switch (type) {

    case CLEAR_OUT_OF_SALE_REVENUE_STATES: return INITIAL_STATE;

    case OUT_OF_SALE_REVENUE_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
