
const INITIAL_STATE = {
  desks: [],
  tempDesks: [],
  categories: [],
  tableLoading: true,
  categoryLoading: true,
  combineProcess: false,
  selectedCategoryId: -2,
  addOrderModal: false,
  selectedDesk: undefined,
  fromTable: false,
  flip: false,
  isLocked: false,
};

export default INITIAL_STATE;


