
const INITIAL_STATE = {

  selectedProducts: undefined,
  updatedProducts: undefined,
  updatedProductsErrors: undefined,
  isRefresh: false,
  optionGroups: [],
  units: []

};
export default INITIAL_STATE;


