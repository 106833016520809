import {
  CLEAR_EVENT_STATES,
  DELETE_EVENT_FROM_LIST,
  EVENT_STATE_UPDATE,
  UPDATE_EVENT,
  UPDATE_EVENT_LIST
} from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IEventDTO } from '../types/IEventDTO';

export interface IEventsReducer {
  eventList: IEventDTO[];
  popupState?: {
    key: string;
    label?: string;
    open: boolean;
    event?: IEventDTO;
    startDate?: string;
  },
  minDate: string,
  maxDate: string

}

const INITIAL_STATE: IEventsReducer = {
  eventList: [],
  popupState: {
    key: '',
    label: '',
    open: false,
  },
  minDate: '',
  maxDate: ''
};

export default function EventsReducer(state = INITIAL_STATE, { type, payload }: IAction): IEventsReducer {

  switch (type) {

    case CLEAR_EVENT_STATES: return INITIAL_STATE;

    case EVENT_STATE_UPDATE: return { ...state, ...payload };

    case UPDATE_EVENT_LIST: {

      const newList = [...state.eventList, ...payload];

      const arrayUniqueByKey = [...new Map(newList.map((item) => [item.id, item])).values()];

      return { ...state, eventList: arrayUniqueByKey };

    }

    case DELETE_EVENT_FROM_LIST: return {
      ...state,
      eventList: state.eventList.filter((e) => !payload.includes(Number(e.id)))
    };

    case UPDATE_EVENT: {

      const newEventList = [...state.eventList];

      const eventIndex = newEventList.findIndex((e) => e.id === payload.id);

      if (eventIndex !== -1) newEventList[eventIndex] = { ...newEventList[eventIndex], ...(payload as IEventDTO) };

      return {
        ...state,
        eventList: newEventList
      };

    }

    default: return state;
  }

}
