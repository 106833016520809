import { useEffect } from 'react';
import UserService from '@services/UserService';
import { CookieService } from '@services/CookieService';
import { CookieEnum } from '@assets/enums/CookieEnum';
import { updateAppReducer } from '@modules/app/redux/AppActions';
import { Routes } from '@services/RoutingService';

export default function CookieHydrator({ children }: { children: any }) {

  const cookie = parse(CookieService.getCookie(CookieEnum.Opsiyon) || '{}') as any;

  const businessId = CookieService.getCookie(CookieEnum.BusinessId) || '' as string;

  useEffect(() => {

    if (!cookie?.tokens && !cookie?.business && !cookie?.personnel && !businessId) {

      if (window.location.pathname !== '/' && window.location.pathname !== Routes.forgotPassword) UserService.signout();

    } else {

      updateAppReducer({ businessId });

      UserService.setUserProperty('tokens', cookie?.tokens);

      UserService.setUserProperty('personnel', cookie?.personnel);

      UserService.setUserProperty('business', cookie?.business);

    }

  }, [businessId, cookie?.business, cookie?.personnel, cookie?.tokens]);

  return children;

}

function parse(cookie: string) {

  try {

    return JSON.parse(cookie);

  } catch (error) {

    return {};

  }

}
