/* eslint-disable max-len */
import { dispatcher } from '@helpers/Redux';
import * as $AT from '@utils/redux/actionTypes';
import { snackActions } from '@components/utils/SnackbarUtils';
import { backdropActions } from '@components/utils/BackDropUtils';
import { getAppState } from '@utils/redux/store';

export const updateAppReducer = (payload: any) => dispatcher({ type: $AT.APP_REDUCER_CHANGE, payload });

export const updateAppReducerDynamic = (payload: any) => dispatcher({ type: $AT.APP_REDUCER_CHANGE_DYNAMIC, payload });

export const getAppReducerState = () => getAppState().AppReducer;

export const getAppReducerDynamicState = () => getAppState().AppReducer.dynamic;

export const showToast = (message: string, variant?: 'default' | 'error' | 'success' | 'warning' | 'info', autoHideDuration?: number) => message && snackActions.toast(message, variant, autoHideDuration);

export const openGeneralLoading = () => backdropActions.open();

export const closeGeneralLoading = () => backdropActions.close();
