import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import './OpsiyonThemeOptions';

const primary = '#9F414B';
const primaryDark = '#75262e';
const primaryLight = '#b5515c';

const secondary = '#3c4043';
const secondaryDark = '#2B2C2E';
const secondaryLight = '#525659';

const white = '#fff';
const black = '#3C4043';
const yellow = '#ffe900';
const subTextColor = '#c1bfbf';
const error = '#F44335';
const success = '#149414';
const gray = '#E0E0E0';
const blue = '#35507E';

const _opsiyonTheme: ThemeOptions = {
  overrides: {

    MuiTable: {
      root: {
        overflow: 'auto',
        userSelect: 'none'
      },
    },

    MuiTableHead: {
      root: {
        background: 'linear-gradient(90deg, #9F414B 0%, #21839A 100%) !important',
        position: 'sticky',
        zIndex: 10,
        top: 0,
        userSelect: 'none',
      }
    },

    MuiTableCell: {
      head: {
        textAlign: 'center',
        zIndex: 2,
        color: ' #FFF !important',
        fontWeight: 'bold',
        background: 'none !important',
        overflow: ' auto',
        padding: '16px',
        position: 'sticky'
      },
      root: {
        background: 'none !important',
        // padding: '10px',
        // textAlign: 'center',
      },
    },

    MuiTableContainer: {
      root: {
        width: '100%',
        overflowX: 'auto'
      }
    },

    MuiTableSortLabel: {
      root: {
        // paddingLeft: '34px'
      }
    },

  },

  palette: {

    primary: {
      main: primary,
      dark: primaryDark,
      light: primaryLight,
    },

    secondary: {
      main: secondary,
      dark: secondaryDark,
      light: secondaryLight,
    },

    muiTableHeadBg: 'linear-gradient(90deg, #9F414B 0%, #21839A 100%)',

    backgroundShapeColor: primary,

    backButtonColor: white,

    primaryColorBoxShadowFunc: (opacity = '0.2') => `rgba(159, 65, 75, ${opacity})`,

    secondaryColorBoxShadowFunc: (opacity = '0.2') => `rgba(241, 144, 35, ${opacity})`,

    CustomColors: {
      white,
      black,
      yellow,
      subTextColor,
      error,
      success,
      gray,
      blue,
      primaryMain: primary,
      primaryDark,
      primaryLight,
      primarySuccess: success,
      primaryError: error,
      secondaryMain: secondary,
      secondaryDark,
      secondaryLight,
      secondarySuccess: success,
      secondaryError: error,
    },

    opsiyonTableRigthColor: '#21839A',

    reportPrimaryColor: '#586778',

    deskDetailsStartButtonBg: '#9E424C',
    deskDetailsEndButtonBg: '#2A7E95',
    deskDetailsRowDetailBg: '#e5e5e5 !important',
    drawerGradient: 'linear-gradient(90deg, #705A69 0%, #5C6575 100%) !important',
    drawerGradientText: '-webkit-linear-gradient(90deg, #705A69 0%, #5C6575 100%)',
    orderOrderedStarterColor: '#21839A',
    fromDeskTable: '#F92524',
    toDeskTable: '#149414',

    staffLayoutSideBarBg: 'linear-gradient(360deg, #9F414B 14.07%, #21839A 156.08%)',

    cashRegisterBg: '#F3EFE5',
    cashRegisterDeskName: '#F3EFE5',
    cashRegisterBackButtonBg: primary,

    openTableBg: 'linear-gradient(180deg, #21839A -106.74%, #9F414B 102.83%)',
    closeTableBg: 'linear-gradient(180deg, #F3EFE5 -41.74%, #3C4043 100%)',
    reservedTableBg: 'linear-gradient(180deg, #21839A -64.66%, #0B1419 100%);',

    homePageBg: 'linear-gradient(90deg, #F3EFE5 0%, #F3EFE5 47.4%, rgba(255, 255, 255, 0.2) 100%), rgba(255, 255, 255, 0.1);',
    homePageMobilBg: '#D6CEBE',

    pagerSelectedBg: primary,
    pagerBg: white,
    pagerTextColor: white,
    pagerSelectedTextColor: black,
    pagerArrowColor: secondary,
    pagerArrowDisabledColor: '#b7c3cc',
    pagerBorderTopColor: '#606568',

    filterDrawerBg: 'white',

    // Accordion
    accordinTitleColor: secondary,
    accordionWhiteTitleColor: primary,
    accordionBgColor: white,
    accordionPrimaryIconColor: primary,
    accordionWhiteIconColor: primary,

    // CustomDateTimeInput
    CustomDateTimeInputTC: black,
    CustomDateTimeInputBG: white,

    // CustomButtonWithIcon
    CustomButtonBgColor: primary,
    CustomButtonTextColor: white,

    // ContentLayout
    ContentLayoutBgColor: secondary,
    ContentLayoutRouteButtonColor: white,
    ContentLayoutRouteButtonIconColor: white,
    ContentLayoutOpenIcon: white,
    ContentLayoutCloseIconColor: white,


    // Login
    loginPageBgColor: white,
    loginButtonBgColor: white,

    // Home Page
    homePageBgColor: white,
    homeCardBg: 'linear-gradient(180deg, #21839A -64.46%, #9F414B 74.75%, #9F414B 100%)',
    homeAvatarBg: '#E4DED0',
    homeCardTitleBgColor: primaryDark,
    homeCardTitleTextColor: white,
    homeSliderButtonColor: primary,

    // Form

    // CustomRadio
    customRadioColor: secondary,
    customRadioBGColor: white,
    customRadioWrapperBorderColor: primary,
    customRadioSpecColor: white,
    customRadioSpecLabelColor: white,

    // CheckBox
    checkBoxIconColor: white,
    checkBoxRedIconColor: primary,

    // ComboBox
    comboBoxBackgroundColor: white,
    comboBoxTitleColor: primary,
    comboBoxIconColor: primary,
    comboBoxActiveColor: primary,

    // IntervalSlider
    intervalSliderThumbColor: primary,
    intervalSliderSpanColor: white,
    intervalSliderInputTextBgColor: white,
    intervalSliderInputTextColor: primary,

    // Popup Footer
    popupFooterBgColor: white,
    popupFooterCancelButtonBgColor: secondary,
    popupFooterCancelButtonTextColor: white,
    popupFooterCancelButtonIconColor: white,
    popupFooterConfirmButtonBgColor: primary,
    popupFooterConfirmButtonTextColor: white,
    popupFooterConfirmButtonIconColor: white,

    // CustomTextInput
    customTextInputLabelColor: secondaryLight,
    customTextInputTextColor: black,
    customTextInputBorderColor: primary,
    customTextInputFocusedColor: primary,
    customTextInputSpecBGColor: white,

    // FeedbackMainCustomCard
    feedbackCustomCardReadedIconColor: primary,
    feedbackCustomCardBookmarkColor: yellow,
    feedbackCustomCardFooterTextColor: subTextColor,
    feedbackCustomCardEditIconColor: primary,

    // FeedbackPopup
    FeedbackPopupPositiveTitleColor: '#007C00',
    FeedbackPopupNegativeTitleColor: '#F60000',

    // SearchInput
    searchInputTextColor: black,
    searchInputBgColor: white,
    searchInputIconBgColor: primary,
    searchInputIconColor: white,

    // Swiper
    swiperBgColor: white,
    swiperPrevButtonColor: white, // ?? to where ??
    swiperPrevTitleColor: white,

    // Table

    // Toolbar
    tableToolbarBgColor: white,
    tableToolbarTitleColor: secondary,

    // Head
    tableHeadCheckBoxColor: primary,
    tableHeadBorderColor: primary,
    tableHeadTextColor: primary,
    tableHeadArrowBackIcon: black,

    // Body
    tableBodyCheckBoxColor: secondary,
    tableBodyEditIconColor: primary,
    tableBodyPayIconColor: secondaryLight,
    tableBodyDeleteIconBoxColor: primary,

    // Pager
    tablePagerColor: primary,

    // CustomNavigation
    customNavigationBackgroundColor: white,

    // Popup
    popupTitleColor: secondary,
    popupBgColor: white,

    // Calendar
    calendarBgColor: white,
    calendarButtonTextColor: primary,
    calendarButtonBgColor: primary,
    calendarButtonBorderColor: '#D7DAE2',
    calendarButtonHoverBgColor: '#f5d9d9',
    calendarButtonHoverBorderColor: '#f5d9d9',
    calendarButtonActiveBgColor: '#f5d9d9',
    calendarButtonActiveBorderColor: '#f5d9d9',
    calendarEventBgColor: primary,
    calendarEventBorderColor: primary,
    calendarEventTextColor: white,
    calendarEventSelectedOverlayColor: 'rgba(0, 0, 0, 0.25)',
    calendarHeaderCellColor: '#f5d9d9',
    calendarHeaderCellCushionColor: '#A3A6B4',
    calendarToolbarTitleColor: '#4D4F5C',
    calendarDayNumberTextColor: black,
    calendarWeekNumberTextColor: black,
    calendarMontNumberTextColor: black,
    calendarDiaryNumberTextColor: black,

    // personnel process
    personnelProcessButtonBGColor: white,
    personnelProcessButtonColor: primary,
    personnelProcessButtonBorderColor: primary,

    // ProductMain
    productMainStarIconColor: yellow,

    // ProductDetailsMain
    productDetailsMainBackButtonColor: primary,
    // AddOrEditProductMain
    addOrEditProductIconColor: primary,
    materialCheckBoxIconColor: primary,
    addOrEditProductBackButtonColor: primary,

    // CustomFilterButton
    customFilterbuttonColor: primary,
    customFilterbuttonIconColor: primary,
    customFilterbuttonBGColor: white,

    // DebtPopup
    DebtPopupNumericTitleColor: primary,
    // CustomList
    customListBGColor: white,
    customListTitle: primary,
    customListTextColor: black,

    // CustomToBuyButton
    CustomToBuyButtoncolor: primary,
    CustomToBuyButtonBG: white,

    // CustomToBuyList
    CustomToBuyListBG: white,
    CustomToBuyListTitle: primary,

    // EventPopup
    EventDeleteButtonColor: primary,

    // SupplierPaymentsList
    SupplierPaymentsListLabelsColor: primary,

    // CustomCustomerPayment
    CustomCustomerPaymentListLabelColor: primary,
    CustomCustomerPaymentListItemColor: black,

    // KitchenPopup
    KitchenPopupPersonnelListLabelColor: primary,
    KitchenPopupPersonnelListItemColor: black,
    KitchenPopupTransferButtonColor: primary,

    // ShiftPopup
    ShiftPopupPersonnelListLabelColor: primary,
    ShiftPopupPersonnelListItemColor: black,

    // PersonnelDetails
    PersonnelDetailsBorderColor: primary,
    PersonnelDetailsListColor: primary,
    PersonnelDetailsListItemColor: black,
    PersonnelDetailsDeleteButtonColor: primary,
    PersonnelDetailsChartButtonColor: primary,
    PersonnelDetailsFilterButtonColor: primary,
    PersonnelDetailsCloseButtonColor: primary,
    PersonnelDetailsUpdateButtonColor: primary,

    // PopupHeader
    PopupHeaderCancelButtonColor: error,

    // PersonnelAuthorizeSettingsPageMain
    AuthorizeSettingsPageTitleColor: primary,
    AuthorizeSettingsPageButtonColor: primary,
    AuthorizeSettingsPageIconColor: primary,

    // CustomLabelWrapper
    CustomLabelWrapperWhiteLabelColor: white,
    CustomLabelWrapperWhiteBorderColor: white,
    CustomLabelWrapperRedLabelColor: primary,

    // CustomLabel
    CustomLabelTitleColor: primary,
    CustomLabelDescriptionColor: black,

    // CustomCheckBox
    CustomCheckBoxColor: primary,

    // Menu Popup
    MenuUploadPhotoButton: primary,

    // Basket
    BasketIconTextColor: white,

    // KitchenDetail
    IncomingListCustomColor: '#f34949'
  },

  // #endregion

};

const OpsiyonTheme = createMuiTheme(_opsiyonTheme);
export type IOpsiyonTheme = typeof _opsiyonTheme
export default OpsiyonTheme;
