import * as $AT from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import INITIAL_STATE, { IDeskDetailsReducer } from './DeskDetailsStore';

const DeskDetailsReducer = (state:IDeskDetailsReducer = INITIAL_STATE, { type, payload }: AnyAction):IDeskDetailsReducer => {

  switch (type) {

    case $AT.DESK_DETAILS_STATE_UPDATE: return { ...state, ...payload };

    default:
      return state;
  }

};
export default DeskDetailsReducer;
