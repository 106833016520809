import { IAction } from '@src/types/IAction';
import { CLEAR_PRODUCT_STOCK_STATES, PRODUCT_STOCK_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IProductStockReducer } from '../types/IProductStockReducer';

const INITIAL_STATE: IProductStockReducer = {

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function ProductStockReducer(state = INITIAL_STATE, { type, payload }: IAction): IProductStockReducer {

  switch (type) {

    case CLEAR_PRODUCT_STOCK_STATES: return INITIAL_STATE;

    case PRODUCT_STOCK_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
