import * as $AT from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import { ICashUpReducer } from '../types/ICashUpReducer';
import INITIAL_STATE from './CashUpStore';

const CashUpReducer = (state:ICashUpReducer = INITIAL_STATE, { type, payload }: AnyAction):ICashUpReducer => {

  switch (type) {

    case $AT.UPDATE_CASHUP_REDUCER: return { ...state, ...payload };

    case $AT.UPDATE_ACTIVE_ATTENDANCES: {

      const { index, value } = payload;

      const activeAttendances = [...(state?.activeAttendances || [])];

      activeAttendances[index] = { ...value };

      return { ...state, activeAttendances };

    }

    case $AT.UPDATE_NOT_CAME_ATTENDANCES: {

      const { index, value } = payload;

      const notCameAttendances = [...(state?.notCameAttendances || [])];

      notCameAttendances[index] = { ...value };

      return { ...state, notCameAttendances };

    }

    default: return state;
  }

};

export default CashUpReducer;
