import { combineReducers } from 'redux';
import CashReducer from '@modules/cashRegister/redux/DeskReducer';
import OrderReducer from '@modules/order/redux/OrderReducer';
import DeskDetailsReducer from '@modules/deskDetails/redux/DeskDetailsReducer';
import ProductReducer from '@modules/products/product/redux/ProductReducer';
import FeedbackReducer from '@modules/feedback/redux/FeedbackReducer';
import StaffOpsKitchenDepartmentsReducer from '@modules/staffsOps/kitchenDepartments/redux/KitchenReducer';
import StaffOpsPositionReducer from '@modules/staffsOps/positions/redux/PositionReducer';
import StaffOpsAnnouncementReducer from '@modules/staffsOps/announcements/redux/AnnouncementReducer';
import StaffOpsShiftReducer from '@modules/shiftOperations/redux/ShiftReducer';
import MaterialReducer from '@modules/material/redux/MaterialReducer';
import SupplierReducer from '@modules/agenda/supplier/redux/reducer';
import OpsiyonTableReducer from '@modules/opsTable/redux/reducer';
import StaffReducer from '@modules/staffs/redux/StaffReducer';
import AppReducer from '@modules/app/redux/AppReducer';
import DeskBasketReducer from '@modules/deskBasket/redux/DeskBasketReducer';
import KitchenReducer from '@modules/kitchen/redux/KitchenReducer';
import RegularCustomerReducer from '@modules/agenda/regularCustomer/redux/reducer';
import MenuReducer from '@modules/menu/redux/MenuReducer';
import SettingsReducer from '@modules/business/redux/reducer';
import DeskSettingsReducer from '@modules/deskSettings/redux/DeskSettingsReducer';
import EventsReducer from '@modules/agenda/event/redux/EventsReducer';
import ProductStockReducer from '@modules/stock/main/redux/ProductStockReducer';
import MaterialStockReducer from '@modules/stock/main/redux/MaterialStockReducer';
import ProductCasualtyReducer from '@modules/stock/main/redux/ProductCasualtyReducer';
import MaterialCasualtyReducer from '@modules/stock/main/redux/MaterialCasualtyReducer';
import HappyHourReducer from '@modules/products/happyHours/redux/HappyHourReducer';
import CardReducer from '@modules/accounting/card/redux/CardReducer';
import ExpenseReducer from '@modules/accounting/expense/redux/ExpenseReducer';
import OutOfSaleRevenueReducer from '@modules/accounting/outOfSaleRevenue/redux/OutOfSaleRevenueReducer';
import DebitReducer from '@modules/accounting/debit/redux/reducer';
import CreditReducer from '@modules/accounting/credit/redux/CreditReducer';
import CashUpReducer from '@modules/cashUp/redux/CashUpReducer';
import SelfReducer from '@modules/self/redux/SelfReducer';
import OpsiyonReducer from '@modules/opsiyon/redux/OpsiyonReducer';
import StockReducer from '@modules/stock/main/redux/StockReducer';

export default combineReducers({
  AppReducer,
  CashReducer,
  OrderReducer,
  DeskDetailsReducer,
  ProductReducer,
  MenuReducer,
  FeedbackReducer,
  StaffOpsKitchenDepartmentsReducer,
  StaffOpsPositionReducer,
  StaffOpsAnnouncementReducer,
  StaffOpsShiftReducer,
  MaterialReducer,
  SupplierReducer,
  OpsiyonTableReducer,
  StaffReducer,
  SettingsReducer,
  DeskBasketReducer,
  KitchenReducer,
  DeskSettingsReducer,
  RegularCustomerReducer,
  EventsReducer,
  ProductStockReducer,
  MaterialStockReducer,
  ProductCasualtyReducer,
  MaterialCasualtyReducer,
  HappyHourReducer,
  CardReducer,
  ExpenseReducer,
  OutOfSaleRevenueReducer,
  DebitReducer,
  CreditReducer,
  CashUpReducer,
  SelfReducer,
  OpsiyonReducer,
  StockReducer
});
