import * as $AT from '@utils/redux/actionTypes';
import { IMenuDetailDTO } from '@modules/menu/types/IMenuDetailDTO';
import { IAutoSelect } from '@src/types/IAutoSelect';
import { IAction } from '@src/types/IAction';
import { IContent } from '@modules/content/types/IContent';

type MenuStateType = {
  menu?: IMenuDetailDTO,
  popupState: {
    key: string,
    open: boolean,
    data?: any,
    label?: string
  },

  // autocomplete data
  productsNames: IContent[],
  canBeTopMenus: IAutoSelect[]
}

const initialState: MenuStateType = {
  popupState: {
    key: '',
    open: false,
    data: null
  },

  // autocomplete data
  canBeTopMenus: [],
  productsNames: []
};


const MenuReducer = (state = initialState, { type, payload }: IAction): MenuStateType => {

  switch (type) {

    case $AT.MENU_STATE_UPDATE: {

      return { ...state, ...payload };

    }

    case $AT.CLEAR_MENUS_STATE: {

      return initialState;

    }

    case $AT.GET_MENU_BY_ID: {

      return { ...state, menu: payload.menu };

    }

    default: return { ...state, };
  }

};
export default MenuReducer;
