import { INameIntNavigationDTO } from '@src/types/INameIntNavigationDTO';
import { DrawerTypeEnum } from '@assets/enums/DrawerTypeEnum';
import { IAutoSelect } from '@src/types/IAutoSelect';
import { IUndoOrderPaymentDTO } from '../types/IUndoOrderPaymentDTO';
import { IDeskDetailModal } from '../types/IDeskDetailModal';
import { IOrderedDetailDTO } from '../types/IOrderedDetailDTO';
import { IOrderGroupedDTO } from '../types/IOrderGroupedDTO';
import { ICashBillDTO } from '../types/ICashBillDTO';

const INITIAL_STATE: IDeskDetailsReducer = {
  bill: undefined,
  drawerType: DrawerTypeEnum.None
};

export default INITIAL_STATE;

export interface IDeskDetailsReducer {
  bill?: ICashBillDTO,
  notCompletedOrderInfoTypes?: INameIntNavigationDTO[],
  drawerTableDatas?: IOrderGroupedDTO[],
  modal?: IDeskDetailModal,
  discountModalForBill?: boolean,
  selectedOrder?:IOrderGroupedDTO,
  drawerType?: DrawerTypeEnum,
  payments?:INameIntNavigationDTO[],
  ordersToBeReturned?: IOrderedDetailDTO[],
  selectedPaidTableDataId?: number,
  undoOrderPayments?: IUndoOrderPaymentDTO[],
  units?: IAutoSelect[]

}
