import { IAction } from '@src/types/IAction';
import { CLEAR_PRODUCT_CASUALTIE_STATES, PRODUCT_CASUALTIE_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IProductCasualtyReducer } from '../types/IProductCasualtyReducer';

const INITIAL_STATE: IProductCasualtyReducer = {

  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function ProductCasualtyReducer(state = INITIAL_STATE, { type, payload }: IAction): IProductCasualtyReducer {

  switch (type) {

    case CLEAR_PRODUCT_CASUALTIE_STATES: return INITIAL_STATE;

    case PRODUCT_CASUALTIE_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
