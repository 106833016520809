import { IAction } from '@src/types/IAction';
import { CLEAR_DEBIT_STATES, DEBIT_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IDebitOrCreditPaymentPlanDTO } from '../types/IDebitOrCreditPaymentPlanDTO';
import { IDebitOrCreditDetailDTO } from '../types/IDebitOrCreditDetailDTO';

export interface IDebitReducer {
  deleteIdList?: string[];

  popupState: {

    key: string;

    label?: string;

    open: boolean;

    debit?: Partial<IDebitOrCreditDetailDTO>;

    paymentPlan?: IDebitOrCreditPaymentPlanDTO;

    debitOrCreditPaymentPlanId?: string;

    debitOrCreditIdListForPayment?: string[];

  }
}

const INITIAL_STATE: IDebitReducer = {
  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function DebitReducer(state = INITIAL_STATE, { type, payload }: IAction): IDebitReducer {

  switch (type) {

    case CLEAR_DEBIT_STATES: return INITIAL_STATE;

    case DEBIT_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
