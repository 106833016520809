/* eslint-disable @typescript-eslint/no-var-requires */
import Router from 'next/router';

export enum Routes {
  index = '/',
  deskBasket = '/desk-basket',
  deskDetails = '/desk-details',
  deskPaidOrders = '/desk-paid-orders',
  product = '/product',
  staff = '/staff',
  cashRegister = '/cash-register',
  deskSettings = '/desk-settings',
  agenda = '/agenda',
  feedback = '/feedback',
  accounting = '/accounting',
  home = '/home',
  business = '/business',
  kitchens = '/kitchens',
  kitchenDetail = '/kitchen-detail',
  materials = '/materials',
  menus = '/menus',
  orders = '/orders',
  products = '/products',
  settings = '/settings',
  staffs = '/staffs',
  staffOperations = '/staff-operations',
  shiftOperations = '/shift-operations',
  // staffWatch = '/staff-watch',
  stock = '/stock',
  reports = '/reports',
  printers = '/printers',
  opsiyonInfo = '/opsiyon/info',
  opsiyonAnnouncements = '/opsiyon/announcements',
  opsiyonFeedback = '/opsiyon/feedback',
  opsiyonTickets = '/opsiyon/tickets',
  opsiyonTicket = '/opsiyon/ticket',
  opsiyonPurchasePackages = '/opsiyon/purchased-packages',
  userActivityLogs = '/user-activity-logs',
  back = 'back',
  cashUp = '/cash-up',
  cashUpReport = '/cash-up-report',
  orderCancellationTypesService = 'order-cancellation-types',
  reportOrder ='/report/order',
  reportOrderByOrderFrom ='/report/order-by-order-from',
  reportProduct ='/report/product',
  expenseRevenue ='/report/expense-revenue',
  self ='/self',
  pendingOrders = '/pending-orders',
  forgotPassword = '/forgot-password'
}

function RoutingService(cb: (_r: typeof Routes, _router: typeof Router) => any) {

  return cb(Routes, Router);

}

export default RoutingService;
