import React, { forwardRef, useImperativeHandle, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';

let useBackDropRef: any;

const BackDrop = forwardRef((props, ref) => {

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useImperativeHandle(
    ref,
    () => ({

      onOpen() {

        setOpen(true);

      },

      onClose() {

        setOpen(false);

      }
    }),
  );

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="primary" />
    </Backdrop>

  );

});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 99999999
  },
}));

const setUseBackDropRef = (useBackdropRefProp: any) => {

  useBackDropRef = useBackdropRefProp;

};

const BackDropUtils = () => <BackDrop ref={setUseBackDropRef} />;

export const backdropActions = {

  open() {

    useBackDropRef && useBackDropRef?.onOpen();

  },
  close() {

    if (useBackDropRef) useBackDropRef?.onClose();

  }
};


export default BackDropUtils;
