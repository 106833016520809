/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as $AT from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import _ from 'lodash';
import { MaterialUsingEnum } from '@assets/enums/MaterialUsingEnum';
import { WhereFromCameToBasketEnum } from '@assets/enums/WhereFromCameToBasketEnum';
import { v4 as uuidv4 } from 'uuid';
import { IBasketList } from '../types/IBasketList';
import { IProductDetailForCustomerDTO } from '../types/IProductDetailForCustomerDTO';
import INITIAL_STATE from './OrderStore';
import { IOrderReducer } from '../types/IOrderReducer';
import { IMultipleProductDTO } from '../types/IMultipleProductDTO';

const OrderReducer = (state: IOrderReducer = INITIAL_STATE, { type, payload }: AnyAction): IOrderReducer => {

  switch (type) {

    case $AT.ORDER_STATE_UPDATE: return { ...state, ...payload };

    case $AT.ADD_PRODUCT_BASKET_ORDERS: {

      const product: IMultipleProductDTO = payload;

      if (product.defaultPortion) {

        product.selectedPortion = product.defaultPortion;

      }

      const accordionIndex = state.basket.findIndex((s) => s.id === product.id);

      if (accordionIndex !== -1) {

        const newBasket = [...state.basket];

        const productIndex = searchProductOnBasket(product as IProductDetailForCustomerDTO, newBasket, accordionIndex);

        if (productIndex !== -1) {

          newBasket[accordionIndex].list[productIndex].basketCount = Number(newBasket[accordionIndex].list[productIndex].basketCount) + 1;

          return { ...state, basket: newBasket };

        }

        product.basketId = uuidv4();

        product.basketCount = 1;

        product.selected = true;

        newBasket[accordionIndex].list = [...newBasket[accordionIndex].list, product];

        return { ...state, basket: newBasket };

      }


      product.basketId = uuidv4();

      product.basketCount = 1;

      product.selected = true;

      const newBasketItem = {
        id: product.id,
        title: product.name,
        list: [product],
        selected: true
      };

      // @ts-ignore
      return { ...state, basket: [...state.basket, newBasketItem] };

    }

    case $AT.ADD_PRODUCT_BASKET_FROM_MODAL: {

      const countFromModal = payload;

      const product = state.selectedProduct as IProductDetailForCustomerDTO;

      const accordionIndex = state.basket.findIndex((s) => s.id === product.id);

      if (accordionIndex !== -1) {

        const newBasket = [...state.basket];

        const productIndex = searchProductOnBasket(product, newBasket, accordionIndex);

        if (productIndex !== -1) {

          newBasket[accordionIndex].list[productIndex].basketCount += countFromModal;

          return {
            ...state,
            basket: newBasket,
            selectedProduct: undefined,
            productPopup: false
          };

        }

        product.basketCount = countFromModal;

        product.selected = true;

        product.basketId = uuidv4();

        newBasket[accordionIndex].list = [...newBasket[accordionIndex].list, product];

        return {
          ...state,
          basket: newBasket,
          selectedProduct: undefined,
          productPopup: false
        };

      }

      product.basketCount = countFromModal;

      product.selected = true;

      product.basketId = uuidv4();

      return {
        ...state,
        basket: [
          // @ts-ignore
          ...state.basket,
          {
            // @ts-ignore
            id: product.id, title: product.name, list: [product], selected: true
          }],
        selectedProduct: undefined,
        productPopup: false
      };

    }

    case $AT.UPDATE_PRODUCT_BASKET_COUNT: {

      const product: IProductDetailForCustomerDTO = payload?.product;

      const newCount = payload?.newCount;

      const index = payload?.index;

      const accordionIndex = state.basket.findIndex((s) => s.id === product.id);

      if (accordionIndex !== -1) {

        const newBasket = [...state.basket];

        // if (Number(newCount) < 1) {

        //   newBasket[accordionIndex].list.splice(index, 1);

        // } else {

        newBasket[accordionIndex].list[index].basketCount = newCount;

        // }

        if (newBasket[accordionIndex].list.length < 1) {

          newBasket.splice(accordionIndex, 1);

        }

        return { ...state, basket: newBasket };

      }

      return { ...state };

    }

    case $AT.UPDATE_PRODUCT_ORDER: {

      const countFromModal = payload;

      const product = state.selectedProduct as IProductDetailForCustomerDTO;

      product.basketCount = countFromModal;

      product.whereFromCame = WhereFromCameToBasketEnum.Modal;

      const accordionIndex = state.basket.findIndex((s) => s.id === product.id);

      if (accordionIndex !== -1) {

        const newBasket = [...state.basket];

        const newList = [...newBasket[accordionIndex].list];

        const productIndex = newList.findIndex((s) => s.basketId === product.basketId);

        if (productIndex !== -1) {

          newList[productIndex] = { ...product };

          newBasket[accordionIndex].list = [...newList];

          return {
            ...state,
            basket: [...newBasket],
            selectedProduct: undefined,
            productPopup: false
          };

        }

      }

      return state;

    }

    case $AT.DELETE_PRODUCT_BASKET_ORDERS: {

      const product = payload;

      const newBasket = [...state.basket];

      const accordionIndex = state?.basket?.findIndex((s) => s.id === product.id);

      if (accordionIndex !== -1) {

        const productIndex = newBasket[accordionIndex].list.indexOf(product);

        if (newBasket[accordionIndex].list?.length <= 1) {

          newBasket.splice(accordionIndex, 1);

        } else {

          newBasket[accordionIndex].list.splice(productIndex, 1);

        }

        return { ...state, basket: newBasket };

      }

      return { ...state };

    }

    case $AT.DELETE_BASKET_ACCORDION: {

      const index = payload;

      const newBasket = [...state.basket];

      newBasket.splice(index, 1);

      return { ...state, basket: newBasket, };

    }

    case $AT.UPDATE_ALL_BASKET_CHECKED: {

      const newBasket = [...state.basket];

      const checked = !newBasket.every((s) => s.selected);

      newBasket.forEach((s) => {

        s.selected = checked;
        s.list.forEach((p) => {

          p.selected = checked;

        });

      });


      return { ...state, basket: newBasket };

    }

    case $AT.UPDATE_SELECTABLE_MATERIAL: {

      if (state.selectedProduct?.selectableMaterials) {

        const materialId = payload;

        const currentIndex = state.selectedProduct?.selectableMaterials?.findIndex((s) => s.material.id === materialId);

        if (currentIndex !== -1) {

          const newList = [...state.selectedProduct?.selectableMaterials];

          newList[currentIndex].isTakeOut = !newList[currentIndex].isTakeOut;

          if (newList[currentIndex].isTakeOut === true && state.selectedProduct?.extraMaterials) {

            const extra = [...state.selectedProduct?.extraMaterials];

            let extraPrice = state.selectedProduct?.extraPrice || 0;

            const extIndex = newList.findIndex((s) => s.material.id === newList[currentIndex].material.id);

            if (extraPrice !== 0) {

              extraPrice -= Number(extra[extIndex].count) * Number(extra[extIndex].additionalPrice);

            }

            extra[extIndex].count = 0;

            return {
              ...state,
              selectedProduct: {
                ...state.selectedProduct,
                selectableMaterials: newList,
                extraMaterials: extra,
                extraPrice
              }
            };

          }

          return { ...state, selectedProduct: { ...state.selectedProduct, selectableMaterials: newList } };

        }

      }

      return { ...state };

    }

    case $AT.ADD_EXTRA_MATERIAL: {

      if (state.selectedProduct?.extraMaterials) {

        const materialId = payload;

        const currentIndex = state.selectedProduct?.extraMaterials?.findIndex((s) => s.material.id === materialId);

        if (currentIndex !== -1) {

          const newList = [...state.selectedProduct?.extraMaterials];

          newList[currentIndex].count = Number(newList[currentIndex]?.count || 0) + 1;

          const extraMaterials = newList[currentIndex];

          let extraPrice = 0;

          if (extraMaterials?.additionalPrice) {

            if (state?.selectedProduct?.extraPrice) {

              extraPrice = state?.selectedProduct?.extraPrice + extraMaterials?.additionalPrice;

            } else {

              extraPrice = extraMaterials?.additionalPrice;

            }

            return { ...state, selectedProduct: { ...state.selectedProduct, extraMaterials: newList, extraPrice } };

          }

          return { ...state, selectedProduct: { ...state.selectedProduct, extraMaterials: newList } };

        }

      }

      return { ...state };

    }

    case $AT.DELETE_EXTRA_MATERIAL: {

      if (state.selectedProduct?.extraMaterials) {

        const currentIndex = state.selectedProduct?.extraMaterials?.findIndex((s) => s.material.id === payload);

        if (currentIndex !== -1) {

          const newList = [...state.selectedProduct?.extraMaterials];

          if (Number(newList[currentIndex]?.count || 0) > 0) {

            newList[currentIndex].count = Number(newList[currentIndex].count || 1) - 1;

            const extraMaterials = newList[currentIndex];

            let extraPrice = 0;

            if (extraMaterials?.additionalPrice && extraMaterials?.additionalPrice > 0) {

              if (state?.selectedProduct?.extraPrice) {

                extraPrice = state?.selectedProduct?.extraPrice - extraMaterials?.additionalPrice;

              } else {

                extraPrice = extraMaterials?.additionalPrice;

              }

              return { ...state, selectedProduct: { ...state.selectedProduct, extraMaterials: newList, extraPrice } };

            }

            return { ...state, selectedProduct: { ...state.selectedProduct, extraMaterials: newList } };

          }

        }

      }

      return { ...state };

    }

    case $AT.UPDATE_PRODUCT_OPTIONS: {

      const { options, value } = payload;

      const optionsIndex = state.selectedProduct?.optionGroups?.findIndex((s) => s.id === options.id);

      if (optionsIndex !== -1 && state.selectedProduct?.optionGroups) {

        const optionGroups = [...state.selectedProduct?.optionGroups];

        if (optionsIndex !== undefined) {

          optionGroups[optionsIndex].selectedOptions = value;

        }

        return { ...state, selectedProduct: { ...state.selectedProduct, optionGroups } };

      }

      return state;

    }

    case $AT.UPDATE_PRODUCT_PORTIONS: {

      const portionId = payload;
      const groupedMaterialRelations = state?.selectedProduct?.groupedMaterialRelations?.find((s) => s?.productPortionDetail?.portionId === portionId);

      const materials = groupedMaterialRelations?.materialRelations || [];

      if (state?.selectedProduct) {

        const selectedProduct: IProductDetailForCustomerDTO = {

          ...state?.selectedProduct,

          requiredMaterials: materials?.filter((s) => s.materialUsing === MaterialUsingEnum.Required),

          selectableMaterials: materials?.filter((s) => (s.materialUsing === MaterialUsingEnum.Selectable)),

          extraMaterials: materials?.filter((s) => (s.materialUsing === MaterialUsingEnum.OnlyExtra || s.materialUsing === MaterialUsingEnum.Selectable)
          && s?.additionalAmount !== undefined),

          selectedPortion: groupedMaterialRelations?.productPortionDetail

        };

        return { ...state, selectedProduct };

      }

      return state;

    }

    case $AT.UPDATE_ACCORDION_CHECKBOX: {

      const newBasket = [...state.basket];

      const id = payload;

      const index = newBasket.findIndex((s) => s.id === id);

      const newSelected = !newBasket[index].selected;

      newBasket[index].selected = newSelected;

      newBasket[index].list.forEach((s) => {

        s.selected = newSelected;

      });

      return { ...state, basket: newBasket };

    }

    case $AT.UPDATE_PRODUCT_CHECKBOX: {

      const newBasket = [...state.basket];

      const { productId, index } = payload;

      const accordionIndex = newBasket.findIndex((s) => s.id === productId);

      const newList = [...newBasket[accordionIndex].list];

      newList[index].selected = !newList[index].selected;

      if (newList.some((s) => s.selected === true)) {

        newBasket[accordionIndex].selected = true;

      }

      if (newList.some((s) => s.selected === false)) {

        newBasket[accordionIndex].selected = false;

      }

      return { ...state, basket: newBasket };

    }

    case $AT.CLEAR_ORDER_PAGE: return { ...INITIAL_STATE };

    default:
      return state;
  }

};

export default OrderReducer;

/** * Aynı ürünlerin farklı varyasyonlarının farklılıklarını bulmak için kullanılır
    * Sepete ürün eklerken kullanılır. 6 adet konrol vardır.
    * Eklenecek ürün sepetteki herhangi bir ürün ile aynı varyosyanlara sahip
    * ise, ürünün sepetteki ürün listesindeki `index` i geriye döndürür,
    * Eğer ürün bulunamazsa geriye -1 döner
  */
const searchProductOnBasket = (product: IProductDetailForCustomerDTO, baskets: IBasketList[], accordionIndex: number): number => {

  const newBasketList = baskets[accordionIndex].list.map((item) => ({
    selectableMaterials: item?.selectableMaterials?.filter((s) => s.isTakeOut === false).map((s) => ({ id: s.material.id })) || [],
    extraMaterials: item?.extraMaterials?.filter((s) => Number(s.count) > 0).map((s) => ({ id: s.material.id, count: s.count })) || [],
    optionGroups: item?.optionGroups?.filter((o) => o.selectedOptions !== undefined)?.map((s) => s.selectedOptions).sort() || [],
    orderNote: item?.orderNote,
    prepareLater: item?.prepareLater,
    portionId: item?.selectedPortion?.portionId
  }));

  const selectedProduct = {
    selectableMaterials: product?.selectableMaterials?.filter((s) => s.isTakeOut === false).map((s) => ({ id: s.material.id })) || [],
    extraMaterials: product?.extraMaterials?.filter((s) => Number(s.count) > 0).map((s) => ({ id: s.material.id, count: s.count })) || [],
    optionGroups: product?.optionGroups?.filter((o) => o.selectedOptions !== undefined)?.map((s) => s.selectedOptions).sort() || [],
    orderNote: product?.orderNote,
    prepareLater: product?.prepareLater,
    portionId: product?.selectedPortion?.portionId
  };

  let productIndex = -1;

  newBasketList.forEach((item, index) => {

    if (item?.portionId === selectedProduct?.portionId && item.orderNote === selectedProduct.orderNote && item.prepareLater === selectedProduct.prepareLater) {

      let isEqual = false;

      const diffSelectable1 = _.differenceWith(item.selectableMaterials, selectedProduct.selectableMaterials, _.isEqual);
      const diffSelectable2 = _.differenceWith(selectedProduct.selectableMaterials, item.selectableMaterials, _.isEqual);

      if (diffSelectable1.length === 0 && diffSelectable2.length === 0) {

        isEqual = true;

      }

      if (isEqual) {

        const diffExtra1 = _.differenceWith(item.extraMaterials, selectedProduct?.extraMaterials, _.isEqual);
        const diffExtra2 = _.differenceWith(selectedProduct.extraMaterials, item?.extraMaterials, _.isEqual);

        if (diffExtra1.length === 0 && diffExtra2.length === 0) {

          isEqual = true;

        } else {

          isEqual = false;

        }

      }

      if (isEqual) {

        const diffOptions1 = _.differenceWith(item.optionGroups, selectedProduct?.optionGroups, _.isEqual);
        const diffOptions2 = _.differenceWith(selectedProduct.optionGroups, item?.optionGroups, _.isEqual);

        if (diffOptions1.length === 0 && diffOptions2.length === 0) {

          isEqual = true;

        } else {

          isEqual = false;

        }

      }

      if (isEqual) {

        productIndex = index;

      }

    }

  });

  return productIndex;

};
