import { IAction } from '@src/types/IAction';
import { CLEAR_CREDIT_STATES, CREDIT_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IDebitOrCreditPaymentPlanDTO } from '../../debit/types/IDebitOrCreditPaymentPlanDTO';
import { IDebitOrCreditDetailDTO } from '../../debit/types/IDebitOrCreditDetailDTO';

export interface ICreditReducer {
  deleteIdList?: string[];
  popupState: {

    key: string;

    label?: string;

    open: boolean;

    debit?: Partial<IDebitOrCreditDetailDTO>;

    paymentPlan?: IDebitOrCreditPaymentPlanDTO;

    debitOrCreditPaymentPlanId?: string;

    debitOrCreditIdListForPayment?: string[];
  }
}

const INITIAL_STATE: ICreditReducer = {
  deleteIdList: [],
  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function CreditReducer(state = INITIAL_STATE, { type, payload }: IAction): ICreditReducer {

  switch (type) {

    case CLEAR_CREDIT_STATES: return INITIAL_STATE;

    case CREDIT_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
