import * as $AT from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IMaterialDetailDTO } from '../types/IMaterialDetailDTO';

interface MaterialStateType {
  unitNames: { id: number, name: string }[],
  material?: IMaterialDetailDTO,
  deleteIdList: number[],
  forceDelete: boolean
  popupState: {
    key: string,
    label: string,
    open: boolean,
    id?: number
  }
}

const INITIAL_STATE: MaterialStateType = {
  unitNames: [],
  deleteIdList: [],
  forceDelete: false,
  popupState: {
    key: '',
    label: '',
    open: false,
    id: undefined,
  },
};

export default function MaterialReducer(state = INITIAL_STATE, { type, payload }: IAction): MaterialStateType {

  switch (type) {

    case $AT.CLEAR_MATERIALS_STATE: {

      return { ...INITIAL_STATE };

    }

    case $AT.SET_MATERIAL_DELETE_STATE: {

      return { ...state, deleteIdList: payload.deleteIdList, forceDelete: payload.forceDelete, };

    }

    case $AT.CLEAR_MATERIAL_STATE: {

      return { ...state, material: undefined, };

    }

    case $AT.GET_MATERIAL_BY_ID: {

      return { ...state, material: payload };

    }

    case $AT.SET_MATERIAL_POPUP_STATE: {

      return { ...state, popupState: { ...state.popupState, ...payload }, };

    }

    case $AT.MATERIAL_PAGE_GET_UNIT_NAMES: {

      return { ...state, unitNames: payload };

    }

    default: return state;
  }

}
