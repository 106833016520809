import * as $AT from '@utils/redux/actionTypes';
import { ICashListDTO } from '@modules/cashRegister/types/ICashListDTO';
import { AnyAction } from 'redux';
import INITIAL_STATE from './DeskStore';
import { ICashReducer } from '../types/ICashReducer';

const CashReducer = (state: ICashReducer = INITIAL_STATE, { type, payload }: AnyAction): ICashReducer => {

  switch (type) {

    case $AT.DESK_STATE_UPDATE: return { ...state, ...payload };

    case $AT.TABLE_COMBINE_CANCELED: {

      const deskList = [...state.desks];

      deskList.forEach((item) => {

        if (item.fromTable) item.fromTable = false;
        if (item.toTable) item.toTable = false;

      });

      return { ...state, combineProcess: false, desks: [...deskList] };

    }

    case $AT.TABLE_COMBİNE_ADD:
    {

      const desk : ICashListDTO = { ...payload };

      const deskList = [...state.desks];

      let fromTable = false;

      if (desk.fromTable) {

        deskList.forEach((item) => {

          if (item.fromTable) item.fromTable = false;

          if (item.toTable) item.toTable = false;

        });

      } else {

        let fromTableIndex = -1;

        let deskIndex = -1;

        deskList.forEach((item, index) => {

          if (item.fromTable) fromTableIndex = index;

          if (desk.id === item.id) deskIndex = index;

        });

        if (fromTableIndex !== -1) {

          deskList.forEach((item) => {

            if (item.toTable) item.toTable = false;

          });

          if (!desk.toTable) deskList[deskIndex].toTable = true;

        } else {

          deskList[deskIndex].fromTable = true;

          fromTable = true;

        }

      }

      return { ...state, desks: [...deskList], fromTable };

    }

    case $AT.TABLE_SEARCH_BY_NAME: {

      if (payload === '') {

        return { ...state, desks: [...state?.tempDesks] };

      }

      const newDesks = state.tempDesks
        .filter((table) => (table?.name?.toLowerCase() === payload.toLowerCase()
                                || table?.name?.toLowerCase().includes(payload.toLowerCase())
                                || table?.note?.toLowerCase().includes(payload.toLowerCase())));

      return { ...state, desks: [...newDesks] };

    }

    default:
      return state;
  }

};
export default CashReducer;
