import React from 'react';
import { makeStyles } from '@material-ui/core';

export default function BackgroundProvider({ children }: any) {

  const classes = useStyles();

  return (
    <div id="rootBackground" className={classes.root}>
      {children}
    </div>
  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    background: process.env.NODE_ENV === 'development' ? '#FFF' : theme.palette.homePageBg,
    [theme.breakpoints.down('md')]: {
      background: theme.palette.homePageMobilBg,
      height: '100%',
    }
  }
}));
