/* eslint-disable import/prefer-default-export */
/** Determines the type of kitchen for the order. */
export enum OrderKitchenType {
    /** Orders are sent to the kitchen normally. */
    Normal,

    /** Orders are sent to the kitchen urgently. */
    Urgent,

    /** Orders are not sent to the kitchen. */
    Direct,

    /** Orders are sent to the kitchen as a priority. */
    Priority,

    /** Orders are calculated and sent to be prepared at the same time in the kitchen. */
    SameTime
}
