
export const APP_REDUCER_CHANGE = 'APP_REDUCER_CHANGE';
export const APP_REDUCER_CHANGE_DYNAMIC = 'APP_REDUCER_CHANGE_DYNAMIC';
export const SET_USER_PROPERTY = 'SET_USER_PROPERTY';

// #region opsiyon table
export const OPS_TABLE_STATE_UPDATE = 'OPS_TABLE_STATE_UPDATE';
export const CLEAR_OPS_TABLE_STATE = 'CLEAR_OPS_TABLE_STATE';
export const CLEAR_ALL_OPS_TABLE_STATE = 'CLEAR_ALL_OPS_TABLE_STATE';
export const OPS_TABLE_ROW_CLICK = 'OPS_TABLE_ROW_CLICK';

// #endregion

export const KITCHEN_STATE_UPDATE = 'KITCHEN_STATE_UPDATE';

export const DESK_DETAILS_STATE_UPDATE = 'DESK_DETAILS_STATE_UPDATE';
export const DESK_BASKET_STATE_UPDATE = 'DESK_BASKET_STATE_UPDATE';
export const BASKET_UPDATE_SELECTABLE_MATERIAL = 'BASKET_UPDATE_SELECTABLE_MATERIAL';
export const BASKET_ADD_EXTRA_MATERIAL = 'BASKET_ADD_EXTRA_MATERIAL';
export const BASKET_DELETE_EXTRA_MATERIAL = 'BASKET_DELETE_EXTRA_MATERIAL';
export const BASKET_UPDATE_PRODUCT_PORTIONS = 'BASKET_UPDATE_PRODUCT_PORTIONS';

// #region staff
export const STAFF_STATE_UPDATE = 'STAFF_STATE_UPDATE';
export const UPDATED_STAFF_OBJECT = 'UPDATED_STAFF_OBJECT';
export const UPDATED_STAFF_OBJECT_ERROR = 'UPDATED_STAFF_OBJECT_ERROR';
export const STAFF_SHIFT_UPDATE = 'STAFF_SHIFT_UPDATE';
export const CLEAR_STAFFS_STATE = 'CLEAR_STAFFS_STATE';

// #endregion

// #region products
export const CLEAR_PRODUCTS_STATE = 'CLEAR_PRODUCTS_STATE';
export const PRODUCTS_STATE_UPDATE = 'PRODUCTS_STATE_UPDATE';
export const UPDATED_PRODUCTS_OBJECT = 'UPDATED_PRODUCTS_OBJECT';
export const UPDATED_PRODUCTS_OBJECT_LANGS = 'UPDATED_PRODUCTS_OBJECT_LANGS';
export const UPDATED_PRODUCTS_OBJECT_ERROR = 'UPDATED_PRODUCTS_OBJECT_ERROR';
export const ADD_OPTIONS_GROUP = 'ADD_OPTIONS_GROUP';
export const DELETE_OPTIONS_GROUP = 'DELETE_OPTIONS_GROUP';
export const ADD_OPTIONS = 'ADD_OPTIONS';
export const DELETE_OPTIONS = 'DELETE_OPTIONS';

// #endregion

// #region happy hour

export const HAPPY_HOUR_STATE_UPDATE = 'HAPPY_HOUR_STATE_UPDATE';
export const CLEAR_HAPPY_HOUR_STATE = 'CLEAR_HAPPY_HOUR_STATE';

// #endregion

// #region order
export const ORDER_STATE_UPDATE = 'ORDER_STATE_UPDATE';
export const UPDATE_ALL_BASKET_CHECKED = 'UPDATE_ALL_BASKET_CHECKED';
export const ADD_PRODUCT_BASKET_ORDERS = 'ADD_PRODUCT_BASKET_ORDERS';
export const UPDATE_PRODUCT_BASKET_COUNT = 'UPDATE_PRODUCT_BASKET_COUNT';
export const DELETE_PRODUCT_BASKET_ORDERS = 'DELETE_PRODUCT_BASKET_ORDERS';
export const DELETE_BASKET_ACCORDION = 'DELETE_BASKET_ACCORDION';
export const ADD_PRODUCT_BASKET_FROM_MODAL = 'ADD_PRODUCT_BASKET_FROM_MODAL';
export const UPDATE_PRODUCT_ORDER = 'UPDATE_PRODUCT_ORDER';
export const CLEAR_ORDER_PAGE = 'CLEAR_ORDER_PAGE';
export const UPDATE_SELECTABLE_MATERIAL = 'UPDATE_SELECTABLE_MATERIAL';
export const ADD_EXTRA_MATERIAL = 'ADD_EXTRA_MATERIAL';
export const DELETE_EXTRA_MATERIAL = 'DELETE_EXTRA_MATERIAL';
export const UPDATE_PRODUCT_OPTIONS = 'UPDATE_PRODUCT_OPTIONS';
export const UPDATE_PRODUCT_PORTIONS = 'UPDATE_PRODUCT_PORTIONS';
export const UPDATE_PRODUCT_CHECKBOX = 'UPDATE_PRODUCT_CHECKBOX';
export const UPDATE_ACCORDION_CHECKBOX = 'UPDATE_ACCORDION_CHECKBOX';

// #endregion

// #region Self
export const SELF_ORDER_STATE_UPDATE = 'ORDER_STATE_UPDATE';
export const SELF_ADD_PRODUCT_BASKET_ORDERS = 'SELF_ADD_PRODUCT_BASKET_ORDERS';
export const SELF_UPDATE_PRODUCT_BASKET_COUNT = 'SELF_UPDATE_PRODUCT_BASKET_COUNT';
export const SELF_DELETE_PRODUCT_BASKET_ORDERS = 'SELF_DELETE_PRODUCT_BASKET_ORDERS';
export const SELF_ADD_PRODUCT_BASKET_FROM_MODAL = 'SELF_ADD_PRODUCT_BASKET_FROM_MODAL';
export const SELF_UPDATE_PRODUCT_ORDER = 'SELF_UPDATE_PRODUCT_ORDER';
export const SELF_CLEAR_ORDER_PAGE = 'SELF_CLEAR_ORDER_PAGE';
export const SELF_UPDATE_SELECTABLE_MATERIAL = 'SELF_UPDATE_SELECTABLE_MATERIAL';
export const SELF_ADD_EXTRA_MATERIAL = 'SELF_ADD_EXTRA_MATERIAL';
export const SELF_DELETE_EXTRA_MATERIAL = 'SELF_DELETE_EXTRA_MATERIAL';
export const SELF_UPDATE_PRODUCT_OPTIONS = 'SELF_UPDATE_PRODUCT_OPTIONS';
export const SELF_UPDATE_PRODUCT_PORTIONS = 'SELF_UPDATE_PRODUCT_PORTIONS';
export const SELF_UPDATE_PRODUCT_CHECKBOX = 'SELF_UPDATE_PRODUCT_CHECKBOX';
export const SELF_UPDATE_ALL_CHECKBOX = 'SELF_UPDATE_ALL_CHECKBOX';
export const SELF_APPLY_DISCOUNT = 'SELF_APPLY_DISCOUNT';

// #endregion

// #region table
export const DESK_STATE_UPDATE = 'DESK_STATE_UPDATE';
export const TABLE_COMBINE_CANCELED = 'TABLE_COMBINE_CANCELED';
export const TABLE_COMBİNE_ADD = 'TABLE_COMBİNE_ADD';
export const TABLE_SEARCH_BY_NAME = 'TABLE_SEARCH_BY_NAME';

// #endregion

// #region tableOrder
export const TABLE_ORDER_INITIAL_ORDERS = 'TABLE_ORDER_INITIAL_ORDERS';
export const TABLE_ORDER_CLEAR = 'TABLE_ORDER_CLEAR';
export const ADD_DRAWER_ORDER = 'ADD_DRAWER_ORDER';

// #endregion

// #region DESKS

export const GET_ALL_DESKS = 'GET_ALL_DESKS';
export const CHANGE_DESK_PROCESS_TYPE = 'CHANGE_DESK_PROCESS_TYPE';
export const CHANGE_DESK_POPUP_STATE = 'CHANGE_DESK_POPUP_STATE';
export const CLEAR_DESK_STATE = 'CLEAR_DESK_STATE';
export const CLEAR_DESK_CURRENT_CATEGORY = 'CLEAR_DESK_CURRENT_CATEGORY';
export const ADD_DESK_TO_CHECK_LIST = 'ADD_DESK_TO_CHECK_LIST';
export const REMOVE_DESK_FROM_CHECK_LIST = 'REMOVE_DESK_FROM_CHECK_LIST';

export const EDİT_TABLE_SETTİNG = 'EDİT_TABLE_SETTİNG';
export const DELETE_TABLE_SETTİNG = 'DELETE_TABLE_SETTİNG';
export const EDİT_CATEGORY_SETTİNG = 'EDİT_CATEGORY_SETTİNG';
export const DELETE_CATEGORY_SETTİNG = 'DELETE_CATEGORY_SETTİNG';

// #endregion

// #region tableBasket

export const GET_TABLE_BASKET_BY_ID = 'GET_TABLE_BASKET_BY_ID';
export const POST_TABLE_BASKET_BY_ID = 'POST_TABLE_BASKET_BY_ID';
export const ADD_PRODUCT_TABLE_BASKET = 'ADD_PRODUCT_TABLE_BASKET';
export const DELETE_PRODUCT_TABLE_BASKET = 'ADD_PRODUCT_TABLE_BASKET';
export const SET_POPUP_TABLE_BASKET = 'SET_POPUP_TABLE_BASKET';
export const CLOSE_POPUP_TABLE_BASKET = 'CLOSE_POPUP_TABLE_BASKET';
export const CLEAR_TABLE_BASKET = 'CLEAR_TABLE_BASKET';

// #endregion

// #region Menu
export const MENU_STATE_UPDATE = 'MENU_STATE_UPDATE';
export const CLEAR_MENUS_STATE = 'CLEAR_MENUS_STATE';
export const GET_MENU_BY_ID = 'GET_MENU_BY_ID';

// #endregion

// #region feedback

export const SET_ACTION_STATE = 'SET_ACTION_STATE';
export const FEEDBACK_LOADING = 'FEEDBACK_LOADING';

export const DISCARD_OR_SET_SELECTED = 'DISCARD_OR_SET_SELECTED';

export const CONFIRM_CHANGES_BY_TYPE = 'CONFIRM_CHANGES_BY_TYPE';

export const GET_ALL_FEEDBACKS = 'GET_ALL_FEEDBACKS';

export const CLEAR_FEEDBACKS = 'CLEAR_FEEDBACKS';


// #endregion

// #region staffOperations

// #region kitchen

export const CLEAR_KITCHEN_DEPARTMENTS = 'CLEAR_KITCHEN_DEPARTMENTS';

export const CLEAR_KITCHEN_DEPARTMENT_STATE = 'CLEAR_KITCHEN_DEPARTMENT_STATE';

export const KITCHEN_DEPARTMENT_STATE_UPDATE = 'KITCHEN_DEPARTMENT_STATE_UPDATE';

// #endregion

// #region position

export const CLEAR_POSITIONS = 'CLEAR_POSITIONS';
export const POSITION_STATE_UPDATE = 'POSITION_STATE_UPDATE';
export const CLEAR_POSITION_STATE = 'CLEAR_POSITION_STATE';

// #endregion

// #region announcements

export const CLEAR_ANNOUNCEMENTS = 'CLEAR_ANNOUNCEMENTS';
export const ANNOUNCEMENT_STATE_UPDATE = 'ANNOUNCEMENT_STATE_UPDATE';
export const CLEAR_ANNOUNCEMENT_STATE = 'CLEAR_ANNOUNCEMENT_STATE';

// #endregion

// #region shift

export const CLEAR_SHIFTS = 'CLEAR_SHIFTS';
export const SHIFT_STATE_UPDATE = 'SHIFT_STATE_UPDATE';
export const CLEAR_SHIFT_STATE = 'SHIFT_STATE_UPDATE';

// #endregion

// #region attendances

export const CLEAR_ATTENDANCES = 'CLEAR_ATTENDANCES';
export const ATTENDANCE_STATE_UPDATE = 'ATTENDANCE_STATE_UPDATE';
export const CLEAR_ATTENDANCE_STATE = 'CLEAR_ATTENDANCE_STATE';
export const SET_STAFFS_SHIFT_FOR_ATTENDANCE = 'SET_STAFFS_SHIFT_FOR_ATTENDANCE';

// #endregion

// #endregion

// #region material Page

// #region Material

export const GET_MATERIAL_BY_ID = 'GET_MATERIAL_BY_ID';

export const SET_MATERIAL_DELETE_STATE = 'SET_MATERIAL_DELETE_STATE';

export const CLEAR_MATERIALS_STATE = 'CLEAR_MATERIALS_STATE';

export const CLEAR_MATERIAL_STATE = 'CLEAR_MATERIAL_STATE';

export const SET_MATERIAL_POPUP_STATE = 'SET_MATERIAL_POPUP_STATE';

export const MATERIAL_PAGE_GET_UNIT_NAMES = 'MATERIAL_PAGE_GET_UNIT_NAMES';

// #endregion

// #region Unit
export const GET_ALL_UNITS = 'GET_ALL_UNITS';

export const UNIT_PAGE_HANDLE_CHANGE_PAGE = 'UNIT_PAGE_HANDLE_CHANGE_PAGE';

export const SET_UNIT_DATA = 'SET_UNIT_DATA';

export const CLEAR_UNITS = 'CLEAR_UNITS';

export const DELETE_UNITS = 'DELETE_UNITS';

export const SET_UNIT_PAGE_POPUP_STATE = 'SET_UNIT_PAGE_POPUP_STATE';

export const GET_UNIT_BY_ID = 'GET_UNIT_BY_ID';

export const CLEAR_UNIT_STATE = 'CLEAR_UNIT_STATE';

export const ADD_UNIT = 'ADD_UNIT';

export const UPDATE_UNIT = 'UPDATE_UNIT';

// #endregion

// #endregion

// #region agenda

// #region supplier
export const CLEAR_SUPPLIER_STATES = 'CLEAR_SUPPLIER_STATES';
export const SUPPLIER_STATE_UPDATE = 'SUPPLIER_STATE_UPDATE';

// #endregion

// #region events

export const CLEAR_EVENT_STATES = 'CLEAR_EVENT_STATES';
export const EVENT_STATE_UPDATE = 'EVENT_STATE_UPDATE';
export const UPDATE_EVENT_LIST = 'UPDATE_EVENT_LIST';
export const DELETE_EVENT_FROM_LIST = 'DELETE_EVENT_FROM_LIST';
export const UPDATE_EVENT = 'UPDATE_EVENT';

// #endregion

// #region regular customer

export const CLEAR_REGULAR_CUSTOMER_STATES = 'CLEAR_REGULAR_CUSTOMER_STATES';
export const REGULAR_CUSTOMER_STATE_UPDATE = 'REGULAR_CUSTOMER_STATE_UPDATE';


// #endregion

// #endregion

// #region stock

export const STOCK_STATE_UPDATE = 'STOCK_STATE_UPDATE';
export const CLEAR_PRODUCT_STOCK_STATES = 'CLEAR_PRODUCT_STOCK_STATES';
export const PRODUCT_STOCK_STATE_UPDATE = 'PRODUCT_STOCK_STATE_UPDATE';

export const CLEAR_MATERIAL_STOCK_STATES = 'CLEAR_MATERIAL_STOCK_STATES';
export const MATERIAL_STOCK_STATE_UPDATE = 'MATERIAL_STOCK_STATE_UPDATE';

export const CLEAR_PRODUCT_CASUALTIE_STATES = 'CLEAR_PRODUCT_CASUALTIE_STATES';
export const PRODUCT_CASUALTIE_STATE_UPDATE = 'PRODUCT_CASUALTIE_STATE_UPDATE';

export const CLEAR_MATERIAL_CASUALTIE_STATES = 'CLEAR_MATERIAL_CASUALTIE_STATES';
export const MATERIAL_CASUALTIE_STATE_UPDATE = 'MATERIAL_CASUALTIE_STATE_UPDATE';

// #endregion

// #region accounting

// #region debit

export const CLEAR_DEBIT_STATES = 'CLEAR_DEBIT_STATES';
export const DEBIT_STATE_UPDATE = 'DEBIT_STATE_UPDATE';

// #endregion

// #region credit

export const CLEAR_CREDIT_STATES = 'CLEAR_CREDIT_STATES';
export const CREDIT_STATE_UPDATE = 'CREDIT_STATE_UPDATE';

// #endregion

// #region Expense

export const CLEAR_EXPENSE_STATES = 'CLEAR_EXPENSE_STATES';
export const EXPENSE_STATE_UPDATE = 'EXPENSE_STATE_UPDATE';

// #endregion

// #region OutOfSaleRevenue

export const CLEAR_OUT_OF_SALE_REVENUE_STATES = 'CLEAR_OUT_OF_SALE_REVENUE_STATES';
export const OUT_OF_SALE_REVENUE_STATE_UPDATE = 'OUT_OF_SALE_REVENUE_STATE_UPDATE';

// #endregion

// #region Card

export const CLEAR_CARD_STATES = 'CLEAR_CARD_STATES';
export const CARD_STATE_UPDATE = 'CARD_STATE_UPDATE';

// #endregion

// #endregion

// #region personnel
export const GET_ALL_PERSONNELS = 'GET_ALL_PERSONNELS';

export const GET_PERSONNEL_DETAIL_AUTO_SELECT_DATAS = 'GET_PERSONNEL_DETAIL_AUTO_SELECT_DATAS';

export const CHANGE_PAGE = 'PERSONNEL_CHANGE_PAGE';

export const GET_PERSONNEL_BY_ID = 'GET_PERSONNEL_BY_ID';

export const CLEAR_PERSONNELS_STATE = 'CLEAR_PERSONNELS_STATE';

export const SET_PERSONNELS = 'SET_PERSONNELS';

export const CLEAR_PERSONNEL_STATE = 'CLEAR_PERSONNEL_STATE';

export const FILTER_PERSONNELS = 'FILTER_PERSONNELS';

export const ADD_PERSONNEL = 'ADD_PERSONNEL';

export const UPDATE_PERSONNEL = 'UPDATE_PERSONNEL';

export const DELETE_PERSONNEL = 'DELETE_PERSONNEL';

export const UPDATE_POSITIONS_STATE = 'UPDATE_POSITIONS_STATE';

export const UPDATE_TABLES_STATE = 'UPDATE_TABLES_STATE';

export const UPDATE_KITCHEN_DEPARTMENTS_STATE = 'UPDATE_KITCHEN_DEPARTMENTS_STATE';

export const UPDATE_PERSONNEL_IMAGE = 'UPDATE_PERSONNEL_IMAGE';

export const UPDATE_SHIFTS_STATE = 'UPDATE_SHIFTS_STATE';

export const GET_PERSONNEL_ROLLCALLS_BY_ID = 'GET_PERSONNEL_ROLLCALLS_BY_ID';

export const PERSONNEL_DETAIL_SET_IS_EDIT_STATE = 'PERSONNEL_DETAIL_SET_IS_EDIT_STATE';

export const PERSONNEL_DETAIL_SET_IS_READ_ONLY_STATE = 'PERSONNEL_DETAIL_SET_IS_READ_ONLY_STATE';

export const PERSONNEL_DETAIL_SET_IMAGE_STATE = 'PERSONNEL_DETAIL_SET_IMAGE_STATE';

export const PERSONNEL_DETAIL_SET_ACTIONS_STATE = 'PERSONNEL_DETAIL_SET_ACTIONS_STATE';

// #endregion

// #region settings

export const UPDATE_SETTINGS_STATE = 'UPDATE_SETTINGS_STATE';
export const CLEAR_SETTINGS_STATE = 'CLEAR_SETTINGS_STATE';

// #endregion

// #region desk-settings

export const DESK_SETTINGS_STATE_UPDATE = 'DESK_SETTINGS_STATE_UPDATE';
export const CLEAR_DESK_SETTINGS = 'CLEAR_DESK_SETTINGS';
export const GET_ALL_DESK_CATEGORIES = 'GET_ALL_DESK_CATEGORIES';
export const CHANGE_DESK_CURRENT_CATEGORY = 'CHANGE_DESK_CURRENT_CATEGORY';
export const DESKS_FILTER_BY_NAME = 'DESKS_FILTER_BY_NAME';

// #endregion

export const UPDATE_CASHUP_REDUCER = 'UPDATE_CASHUP_REDUCER';
export const UPDATE_ACTIVE_ATTENDANCES = 'UPDATE_ACTIVE_ATTENDANCES';
export const UPDATE_NOT_CAME_ATTENDANCES = 'UPDATE_NOT_CAME_ATTENDANCES';

export const UPDATE_OPSIYON_STATE = 'UPDATE_OPSIYON_STATE';
