import { IAction } from '@src/types/IAction';
import {
  CLEAR_DESK_SETTINGS,
  DESK_SETTINGS_STATE_UPDATE,
  CHANGE_DESK_CURRENT_CATEGORY,
  DESKS_FILTER_BY_NAME
} from '@utils/redux/actionTypes';
import { IDeskSettingsReducer } from '../types/IDeskSettingsReducer';

const INITIAL_STATE: IDeskSettingsReducer = {
  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false,
  },

  searchString: ''

};

export default function DeskSettingsReducer(state = INITIAL_STATE, { type, payload }: IAction): IDeskSettingsReducer {

  switch (type) {

    case CLEAR_DESK_SETTINGS: return INITIAL_STATE;

    case DESK_SETTINGS_STATE_UPDATE: return { ...state, ...payload };

    case CHANGE_DESK_CURRENT_CATEGORY: return { ...state, currentCategoryId: payload };

    case DESKS_FILTER_BY_NAME: return { ...state, searchString: payload };

    default: return state;
  }

}
