import { UPDATE_OPSIYON_STATE } from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IOpsiyonReducer } from '../types/IOpsiyonReducer';

const initialState: IOpsiyonReducer = {
};

export default function OpsiyonReducer(state = initialState, { type, payload }: IAction): IOpsiyonReducer {

  switch (type) {

    case UPDATE_OPSIYON_STATE: return { ...state, ...payload };

    default: return state;
  }

}
