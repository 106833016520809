import OpsiyonTheme from '../assets/theme/OpsiyonTheme';

const theme = 'default';

export default function ColorService() {

  switch (theme) {
    case 'default':
      return OpsiyonTheme;
    default:
      return OpsiyonTheme;
  }

}
