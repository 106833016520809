import * as $AT from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IKitchenDepartmentDetailDTO } from '../types/IKitchenDepartmentDetailDTO';

export interface KitchenDepartMentsStateType {
  kitchenDepartment?: IKitchenDepartmentDetailDTO;
  deleteIdList?: number[];
  forceDelete?: boolean;
  popupState?: {
    key: string;
    label?: string;
    open: boolean;
    id?: number;
  }
}

const INITIAL_STATE: KitchenDepartMentsStateType = {
  deleteIdList: [],
  forceDelete: false,
  popupState: {
    key: '',
    label: '',
    open: false,
  }
};

export default function KitchenReducer(state = INITIAL_STATE, { type, payload }: IAction): KitchenDepartMentsStateType {

  switch (type) {

    case $AT.CLEAR_KITCHEN_DEPARTMENTS: {

      return { ...INITIAL_STATE };

    }

    case $AT.KITCHEN_DEPARTMENT_STATE_UPDATE: {

      return { ...state, ...payload };

    }

    case $AT.CLEAR_KITCHEN_DEPARTMENT_STATE: {

      return { ...state, kitchenDepartment: undefined };

    }

    default: return state;
  }

}
