import * as $AT from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IPositionReducer } from '../types/IPositionReducer';

const INITIAL_STATE: IPositionReducer = {
  deleteIdList: [],

  forceDelete: false,

  popupState: {
    key: '',
    label: '',
    open: false,
  },

  roles: [],
};

export default function PositionReducer(state = INITIAL_STATE, { type, payload }: IAction): IPositionReducer {

  switch (type) {


    case $AT.CLEAR_POSITIONS: {

      return { ...INITIAL_STATE };

    }

    case $AT.POSITION_STATE_UPDATE: {

      return { ...state, ...payload };

    }

    case $AT.CLEAR_POSITION_STATE: {

      return { ...state, position: undefined };

    }

    default: return state;
  }

}
