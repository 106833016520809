const INITIAL_STATE = {
  products: [],
  selectedProduct: undefined,
  basket: [],
  menus: [],
  selectedMenu: undefined,
  productPopup: false,
  productPopupLoading: false,
  productDataInfo: undefined
};

export default INITIAL_STATE;


