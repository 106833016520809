import { IAction } from '@src/types/IAction';
import { STOCK_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IStockReducer } from '../types/IStockReducer';

export default function StockReducer(state:IStockReducer = {}, { type, payload }: IAction): IStockReducer {

  switch (type) {

    case STOCK_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
