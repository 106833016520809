import { IAction } from '@src/types/IAction';
import { CLEAR_EXPENSE_STATES, EXPENSE_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IExpenseDTO } from '../types/IExpenseDTO';

export interface IExpenseReducer {
  deleteIdList?: string[];
  popupState: {
    key: string;
    label?: string;
    open: boolean;
    expense?: IExpenseDTO;
  }

}

const INITIAL_STATE: IExpenseReducer = {
  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function ExpenseReducer(state = INITIAL_STATE, { type, payload }: IAction): IExpenseReducer {

  switch (type) {

    case CLEAR_EXPENSE_STATES: return INITIAL_STATE;

    case EXPENSE_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
