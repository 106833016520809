import * as $AT from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { FeedbackDTO } from '../types/FeedbackDTO';
import { IFeedbackPaginationParamsWithSpec } from '../types/IFeedbackPaginationParamsWithSpec';
import { IFeedbackPaginationDTO } from '../types/IFeedbackPaginationDTO';

interface IFeedbackReducer {
  feedbacks: FeedbackDTO[],
  feedbackInfo ?:IFeedbackPaginationParamsWithSpec
  selectedList: Array<FeedbackDTO['id']>,
  isActionsActive: boolean,
  loading: boolean,
}

const INITIAL_STATE:IFeedbackReducer = {
  feedbacks: [],
  selectedList: [],
  isActionsActive: false,
  loading: true

};

export default function FeedbackReducer(state = INITIAL_STATE, { type, payload }: IAction): IFeedbackReducer {

  switch (type) {

    case $AT.GET_ALL_FEEDBACKS: {

      const { data, info } = payload as {data: IFeedbackPaginationDTO, info: IFeedbackPaginationParamsWithSpec};

      return ({
        ...state,
        feedbacks: data?.dtoList,
        feedbackInfo: { ...info, totalDataCount: data.totalDataCount, pageCount: data.pageCount },
        loading: false
      });

    }

    case $AT.CLEAR_FEEDBACKS: {

      return { ...INITIAL_STATE };

    }

    case $AT.FEEDBACK_LOADING: {

      return { ...state, loading: payload };

    }

    case $AT.SET_ACTION_STATE: {

      const props = { isActionsActive: payload, selectedList: state.selectedList };
      if (!payload) props.selectedList = [];

      return {
        ...state,
        ...props
      };

    }

    case $AT.CONFIRM_CHANGES_BY_TYPE: {

      state?.feedbacks?.forEach((feedback) => {

        if (payload.idList.includes(feedback.id)) {

          if (payload.type === 'seen') feedback.isSeen = payload.status;

          if (payload.type === 'flag') feedback.isFlag = payload.status;

        }

      });

      return { ...state };

    }

    case $AT.DISCARD_OR_SET_SELECTED: {

      let _selectedList = [...state.selectedList] as IFeedbackReducer['selectedList'];

      const selectedId = payload as number;

      if (_selectedList.find((item) => item === payload)) {

        _selectedList = _selectedList.filter((selected) => selected !== payload);

      } else _selectedList.push(selectedId);

      return {
        ...state,
        selectedList: _selectedList

      };

    }

    default: {

      return state;

    }
  }

}
