import { CLEAR_HAPPY_HOUR_STATE, HAPPY_HOUR_STATE_UPDATE } from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IHappyHourDetailDTO } from '../types/IHappyHourDetailDTO';

export interface IHappyHourReducer {
  popupState?: {
    key: string;
    label?: string;
    open: boolean;
    id?: string;
    happyHour?: Partial<IHappyHourDetailDTO>;
  },
}

const INITIAL_STATE: IHappyHourReducer = {

  popupState: {
    key: '',
    label: '',
    open: false,
  },
};

export default function HappyHourReducer(state = INITIAL_STATE, { type, payload }: IAction): IHappyHourReducer {

  switch (type) {

    case HAPPY_HOUR_STATE_UPDATE: return { ...state, ...payload };

    case CLEAR_HAPPY_HOUR_STATE: return INITIAL_STATE;

    default: return state;
  }

}
