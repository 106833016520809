/* eslint-disable react/no-danger */
/* eslint-disable react/style-prop-object */
/* eslint-disable @next/next/no-img-element */
/* eslint-disable @next/next/inline-script-id */
/* eslint-disable @next/next/no-script-in-head */
/* eslint-disable @next/next/no-page-custom-font */
import React, { useEffect } from 'react';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import Head from 'next/head';
import { Provider } from 'react-redux';
import OpsiyonProvider from '@src/provider';
import Store from '@utils/redux/store';
import { AppProps } from 'next/app';

// import '@services/LocalizationService';
import '@styles/globals.css';
import '@styles/styles.scss';

interface MyAppProps extends AppProps {
  Component: React.FC;
  pageProps: any
}


function MyApp(props: MyAppProps) {

  const { Component, pageProps, } = props;
  useEffect(() => {

    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles?.parentElement) jssStyles.parentElement.removeChild(jssStyles);

  }, []);

  return (
    <>
      <Head>

        <title>OPS!YON ERP</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />

        {/* <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
          m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
          (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

          ym(88008486, "init", {
                clickmap:true,
                trackLinks:true,
                accurateTrackBounce:true,
                webvisor:true
          });`
          }}
        />

        <noscript><div><img src="https://mc.yandex.ru/watch/88008486" style={{ position: 'absolute', left: -9999 }} alt="" /></div></noscript> */}

      </Head>

      <SnackbarProvider maxSnack={5}>
        <Provider store={Store}>
          <OpsiyonProvider>

            <Component {...pageProps} />

          </OpsiyonProvider>
        </Provider>
      </SnackbarProvider>
    </>
  );

}

export default appWithTranslation(MyApp);
