/* eslint-disable react/destructuring-assignment */
import React, { useEffect, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { logEvent } from 'firebase/analytics';
import { analytics } from 'firebaseConfig';

const FirebaseTrackingProvider = (props: { children: ReactNode }) => {

  const router = useRouter();

  useEffect(() => {

    const handleRouteChange = (url: string) => {

      logEvent(analytics, 'page_view', { page_location: url, page_title: document?.title, });

    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {

      router.events.off('routeChangeStart', handleRouteChange);

    };

  }, [router.events]);

  return (
    <>
      {props.children}
    </>
  );

};

export default FirebaseTrackingProvider;
