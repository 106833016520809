/* eslint-disable import/prefer-default-export */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import 'firebase/performance';

const firebaseConfig = {
  apiKey: 'AIzaSyBljx8L_6ZROASpPRhdSPk7CUkSZguvT6w',
  authDomain: 'ops-yon-erp-a31a5.firebaseapp.com',
  projectId: 'ops-yon-erp-a31a5',
  storageBucket: 'ops-yon-erp-a31a5.appspot.com',
  messagingSenderId: '647755470880',
  appId: '1:647755470880:web:694aeabf79da737236c445',
  measurementId: 'G-5NETEFPKQM'
};

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

export { analytics };
