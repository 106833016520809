/* eslint-disable max-len */
import * as $AT from '@utils/redux/actionTypes';
import { dataSetter } from '@helpers/Data';
import { AnyAction } from 'redux';
import { IUser } from '../types/IUser';
import { IAppReducer } from '../types/IAppReducer';

const INITIAL_STATE: IAppReducer = {
  user: {
    tokens: {
      accessToken: '',
      expiration: '',
      refreshToken: ''
    },
    personnel: {
      id: '',
      nameSurname: '',
      position: '',
      profilePhotoUrl: '',
      roles: [],
      staffType: 1,
      userName: ''
    },
    business: {
      isActive: false,
      tableCount: 0,
      modules: [],
      defaultLanguageId: 1,
      stockOrderModuleRelation: false,
      autoAddToShoppingBasket: false,
      mainCurrencySymbol: '₺',
      usedLanguageIdList: [1],
      institutionId: '',
      mainCurrency: 0,
      lockScreenActive: false,
      pinCode: '0000',
      restrictAccessToFranchiseOperations: false,
      suspended: false
    }
  },
  dynamic: {}
};

const AppReducer = (state: IAppReducer = INITIAL_STATE, { type, payload }: AnyAction): IAppReducer => {

  switch (type) {

    case $AT.APP_REDUCER_CHANGE: return { ...state, ...payload };

    case $AT.SET_USER_PROPERTY: {

      const { key, value }: { key: string, value: any } = payload;

      const newUser = dataSetter({ ...state.user }, key, value) as IUser;

      return { ...state, user: { ...newUser } };

    }

    case $AT.APP_REDUCER_CHANGE_DYNAMIC: return { ...state, dynamic: { ...state.dynamic, ...payload } };

    default: return state;
  }

};

export default AppReducer;

