/* eslint-disable import/prefer-default-export */

export const dataSelector = (obj: Record<string, any> = {}, key: string | Array<string> = '', defaultValue: any = ''): any => {

  if (typeof key === 'string') return dataSelector(obj, key.split('.'), defaultValue);

  if (key?.length === 1 && defaultValue !== undefined) return obj[key[0]] || defaultValue;

  if (key?.length === 0) return obj;

  return dataSelector(obj[key[0]], key?.slice(1), defaultValue);

};

export const dataSetter = (obj: Record<string, any>, path: string, value: any) => {

  let newObject = obj;

  const pList = path.split('.');

  for (let i = 0; i < pList.length - 1; i += 1) {

    const key = pList[i];

    if (!newObject[key]) newObject[key] = {};

    newObject = newObject[key];

  }

  Object.assign(newObject, { [pList[pList.length - 1]]: value });

  return obj;

};
