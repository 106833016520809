/* eslint-disable import/prefer-default-export */
/** Represents the product material using type. It determines how a material is used in the product. */
export enum MaterialUsingEnum {
    /** The material to which this value is assigned must necessarily be present in that product.
     It cannot be removed from the product when ordering. But extra may be requested. */
    Required,

    /** The material to which this value is assigned does not necessarily have to be present in that product,
    but it exists in default. It can be removed at the time of order if desired. Extras may also be requested. */
    Selectable,

    /** The material to which this value is assigned cannot be found in that product by default.
    It can only be requested as an extra. For example, a milk pot next to filter coffee. */
    OnlyExtra
}
