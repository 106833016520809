import { IAction } from '@src/types/IAction';
import { CLEAR_REGULAR_CUSTOMER_STATES, REGULAR_CUSTOMER_STATE_UPDATE } from '@utils/redux/actionTypes';
import { INameIntNavigationDTO } from '@src/types/INameIntNavigationDTO';
import { IRegularCustomerDTO } from '../types/IRegularCustomerDTO';

export interface IRegularCustomerReducer {
  deleteIdList?: number[];
  popupState: {
    key: string;
    label?: string;
    open: boolean;
    regularCustomer?: IRegularCustomerDTO;
  },

  paymentTypes?: INameIntNavigationDTO[]

}

const INITIAL_STATE: IRegularCustomerReducer = {
  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false
  },

  paymentTypes: []

};

export default function RegularCustomerReducer(state = INITIAL_STATE, { type, payload }: IAction): IRegularCustomerReducer {

  switch (type) {

    case CLEAR_REGULAR_CUSTOMER_STATES: return INITIAL_STATE;

    case REGULAR_CUSTOMER_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
