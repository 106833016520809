import { UPDATE_SETTINGS_STATE, CLEAR_SETTINGS_STATE } from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { ISettingReducer } from '../types/ISettingReducer';

const initialState: ISettingReducer = {
};

export default function SettingsReducer(state = initialState, { type, payload }: IAction): ISettingReducer {

  switch (type) {

    case UPDATE_SETTINGS_STATE: return { ...state, ...payload };

    case CLEAR_SETTINGS_STATE: return initialState;

    default: return state;
  }

}
