import TKeyEnum from '@modules/opsTable/types/TKeyEnum';

export const dataKey = (tKey: TKeyEnum) => `${tKey}Data`;
export const columns = (tKey: TKeyEnum) => `${tKey}Columns`;
export const isActionsLeft = (tKey: TKeyEnum) => `${tKey}IsActionsLeft`;
export const showActions = (tKey: TKeyEnum) => `${tKey}ShowActions`;
export const showSection = (tKey: TKeyEnum) => `${tKey}ShowSection`;
export const dataInfoKey = (tKey: TKeyEnum) => `${tKey}Info`;
export const selectedList = (tKey: TKeyEnum) => `${tKey}SelectedList`;
export const selectedItem = (tKey: TKeyEnum) => `${tKey}SelectedItem`;
export const filter = (tKey: TKeyEnum) => `${tKey}Filters`;
