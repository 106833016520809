import { IAction } from '@src/types/IAction';
import { CLEAR_CARD_STATES, CARD_STATE_UPDATE } from '@utils/redux/actionTypes';
import { ICardDTO } from '../types/ICardDTO';

export interface ICardReducer {
  deleteIdList?: string[];
  popupState: {
    key: string;
    label?: string;
    open: boolean;
    card?: ICardDTO;
  }

}

const INITIAL_STATE: ICardReducer = {
  deleteIdList: [],

  popupState: {
    key: '',
    label: '',
    open: false
  }

};

export default function CardReducer(state = INITIAL_STATE, { type, payload }: IAction): ICardReducer {

  switch (type) {

    case CLEAR_CARD_STATES: return INITIAL_STATE;

    case CARD_STATE_UPDATE: return { ...state, ...payload };

    default: return state;
  }

}
