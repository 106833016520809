/* eslint-disable import/prefer-default-export */
export enum CookieEnum {

  Opsiyon = '_opsiyon',
  BusinessId = 'businessId',
  HomeSliderIndex = 'sindex',
  AgendaNavigation = 'agendaNavigation',
  AccountingNavigation = 'accountingNavigation',
  StaffOperationsNavigation = 'staffOperationsNavigation',
  ShiftOperationsNavigation = 'ShiftOperationsNavigation',
  StockNavigation = 'stockNavigation',
  StockNavigationType = 'stockNavigationType',
  DeskSettingsNavigation = 'deskSettingsNavigation',
  ProductNavigation = 'ProductNavigation'


}
