/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { createStore, compose } from 'redux';
import RootReducer from './rootReducer';

const apply = () => {

  if (process.env.NODE_ENV !== 'production') {

    // eslint-disable-next-line import/no-extraneous-dependencies
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools();

  }

  return {};

};

const Store = createStore(RootReducer, compose(apply()));

export default Store;

export type RootState = ReturnType<typeof Store.getState>
export type AppDispatch = typeof Store.dispatch
export const getAppState = Store.getState;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch = () => useDispatch<AppDispatch>();
