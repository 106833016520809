import RoutingService from '@services/RoutingService';
import { dispatcher } from '@helpers/Redux';
import { SET_USER_PROPERTY } from '@utils/redux/actionTypes';
import { getAppState } from '@utils/redux/store';
import { IUser } from '@modules/app/types/IUser';
import { IAccountInfoDTO } from '@modules/app/types/IAccountInfoDTO';
import { CookieEnum } from '@assets/enums/CookieEnum';
import { CookieService } from './CookieService';

class UserService {

  static getUserProperty(key: ['personnel', 'business', 'tokens'][number]) {

    return getAppState().AppReducer.user[key];

  }

  static getUser() {

    return getAppState().AppReducer.user;

  }

  static setUserProperty(key: ['personnel', 'business', 'tokens'][number], value: any) {

    dispatcher({ type: SET_USER_PROPERTY, payload: { key, value } });

    // const { user } = getAppState().AppReducer;

    // const newUser = { ...user, [key]: value };

    // this.setUserToCookie({ business: newUser.business, personnel: newUser.personnel, tokens: newUser.tokens, });

  }

  static hasRule(rule: string) {

    return (UserService.getUserProperty('personnel') as unknown as IAccountInfoDTO).roles.indexOf(rule) !== -1;

  }

  static signin({ tokens, personnel, business }: IUser) {

    dispatcher({ type: SET_USER_PROPERTY, payload: { key: 'tokens', value: tokens } });

    dispatcher({ type: SET_USER_PROPERTY, payload: { key: 'personnel', value: personnel } });

    dispatcher({ type: SET_USER_PROPERTY, payload: { key: 'business', value: business } });

    this.setUserToCookie({ tokens, personnel, business });

  }

  static signout() {

    RoutingService((routes, router) => router.push(routes.index));

  }

  static getUserFromCookie(property: 'business' | 'personnel' | 'tokens') {

    const _cookie = JSON.parse(CookieService.getCookie(CookieEnum.Opsiyon) || '{}');

    if (property) return _cookie[property];

    return _cookie;

  }

  static setUserToCookie({ business, personnel, tokens }: any) {

    CookieService.setCookie(CookieEnum.Opsiyon, JSON.stringify({ business, personnel, tokens }));

  }
}

export default UserService;
