import {
  CLEAR_PRODUCTS_STATE,
  PRODUCTS_STATE_UPDATE,
  UPDATED_PRODUCTS_OBJECT,
  UPDATED_PRODUCTS_OBJECT_ERROR,
  UPDATED_PRODUCTS_OBJECT_LANGS,
  ADD_OPTIONS_GROUP,
  DELETE_OPTIONS_GROUP,
  ADD_OPTIONS,
  DELETE_OPTIONS
} from '@utils/redux/actionTypes';
import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import INITIAL_STATE from './ProductStore';
import { IProductReducer } from '../types/IProductReducer';

const ProductReducer = (state: IProductReducer = INITIAL_STATE, { type, payload }: AnyAction): IProductReducer => {

  switch (type) {

    case CLEAR_PRODUCTS_STATE: return INITIAL_STATE;

    case PRODUCTS_STATE_UPDATE: return { ...state, ...payload };

    case UPDATED_PRODUCTS_OBJECT: return { ...state, updatedProducts: { ...state?.updatedProducts, [payload.property]: payload.value }, };

    case UPDATED_PRODUCTS_OBJECT_ERROR:
      return {
        ...state,
        updatedProducts: { ...state.updatedProducts, [payload.property]: payload.value },
        updatedProductsErrors: { ...state.updatedProductsErrors, [payload.property]: payload.isError },
      };

    case UPDATED_PRODUCTS_OBJECT_LANGS: {

      const { langId, property, value } = payload;

      if (state.updatedProducts?.languages) {

        const languages = [...state.updatedProducts?.languages];

        const langIndex = languages.findIndex((i) => i.systemLanguageId === langId);

        if (langIndex !== -1) {

          const lang = languages[langIndex];

          const newLang = { ...lang, [property]: value };

          languages[langIndex] = newLang;

          return { ...state, updatedProducts: { ...state?.updatedProducts, languages }, };

        }

      }

      if (state?.updatedProducts?.languages) {

        return {
          ...state,
          updatedProducts: { ...state?.updatedProducts, languages: [...state?.updatedProducts?.languages, { systemLanguageId: langId, [property]: value }] },
        };

      }

      return { ...state, updatedProducts: { ...state?.updatedProducts, languages: [{ systemLanguageId: langId, [property]: value }] }, };

    }

    case ADD_OPTIONS_GROUP: {

      const { name, languageId } = payload;

      const optionGroups = [...(state?.optionGroups || [])];

      if (optionGroups && optionGroups.length > 0) {

        const optionGroupIndex = optionGroups.findIndex((s) => s.languageId === languageId);

        if (optionGroupIndex !== -1) {

          const lastIndex = optionGroups[optionGroupIndex]?.list?.length || 0;

          optionGroups[optionGroupIndex].list = [...(optionGroups[optionGroupIndex]?.list || []), { id: lastIndex + 1, name, options: [] }];

          return { ...state, optionGroups };

        }

        return { ...state, optionGroups: [...optionGroups, { languageId, list: [{ id: 1, name, options: [] }] }] };

      }

      return { ...state, optionGroups: [{ languageId, list: [{ id: 1, name, options: [] }] }] };

    }

    case DELETE_OPTIONS_GROUP: {

      const { optionsId, languageId } = payload;

      const optionGroups = [...(state?.optionGroups || [])];

      if (optionGroups) {

        const optionGroupIndex = optionGroups.findIndex((s) => s.languageId === languageId);

        if (optionGroupIndex !== -1) {

          const newOptionGroups = [...(optionGroups[optionGroupIndex].list || [])];

          const groupsIndex = newOptionGroups.findIndex((i) => i.id === optionsId);

          newOptionGroups.splice(groupsIndex, 1);

          optionGroups[optionGroupIndex].list = [...newOptionGroups];

          return { ...state, optionGroups };

        }

      }

      return { ...state };

    }

    case ADD_OPTIONS: {

      const { optionsId, name, languageId } = payload;

      const optionGroups = [...(state?.optionGroups || [])];

      if (optionGroups) {

        const optionGroupIndex = optionGroups.findIndex((s) => s.languageId === languageId);

        if (optionGroupIndex !== -1) {

          const optionGroupList = [...(optionGroups[optionGroupIndex].list || [])];

          optionGroupList.find((i) => i.id === optionsId)?.options.push({ id: uuidv4(), name });

          optionGroups[optionGroupIndex].list = [...optionGroupList];

        }

        return { ...state, optionGroups };

      }

      return { ...state };

    }

    case DELETE_OPTIONS: {

      const { optionsId, productOptions, languageId } = payload;

      const optionGroups = [...(state?.optionGroups || [])];

      if (optionGroups) {

        const optionGroupIndex = optionGroups.findIndex((s) => s.languageId === languageId);

        if (optionGroupIndex !== -1) {

          const newList = optionGroups[optionGroupIndex].list;

          if (newList) {

            const optionsIndex = newList.findIndex((s) => s.id === optionsId);


            if (optionsIndex !== -1) {

              const index = newList[optionsIndex].options.findIndex((s) => s.id === productOptions.id);

              newList[optionsIndex].options.splice(index, 1);

              optionGroups[optionGroupIndex].list = [...newList];

              return { ...state, optionGroups };

            }

          }

        }

      }

      return { ...state };

    }


    default: return { ...state };
  }

};
export default ProductReducer;

