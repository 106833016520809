import * as $AT from '@utils/redux/actionTypes';
import { IAction } from '@src/types/IAction';
import { IShiftReducer } from '../types/IShiftReducer';

const INITIAL_STATE: IShiftReducer = {
  deleteIdList: [],

  forceDelete: false,

  popupState: {
    key: '',
    label: '',
    open: false,
  },

  positions: []
};

export default function ShiftReducer(state = INITIAL_STATE, { type, payload }: IAction): IShiftReducer {

  switch (type) {


    case $AT.CLEAR_SHIFTS: {

      return INITIAL_STATE;

    }

    case $AT.SHIFT_STATE_UPDATE: {

      return { ...state, ...payload };

    }

    case $AT.CLEAR_SHIFT_STATE: {

      return { ...state, shift: undefined };

    }

    default: return state;
  }

}
