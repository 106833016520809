import { OrderKitchenType } from '@assets/enums/OrderKitchenType';
import { ISelfReducer } from '../types/ISelfReducer';

const INITIAL_STATE: ISelfReducer = {
  products: [],
  selectedProduct: undefined,
  basket: [],
  menus: [],
  selectedMenu: undefined,
  productPopup: false,
  productPopupLoading: false,
  productDataInfo: undefined,
  orderKitchenType: OrderKitchenType.Direct,
  peopleCount: 0

};

export default INITIAL_STATE;


